.cart-page {
    font: 100% "Maven Pro",Arial,Helvetica,sans-serif;
    background: url("../img/bg.jpg") center top no-repeat #0c0c0c !important;
}

@media (min-width: 768px) {
.cart-page #main {
    padding: 20px 0 100px;
}
}

.cart-page #logo {
    padding: 12px 0 9px;
    float: none;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
}

.cart-page #menu {
    padding: 0px;
    margin: 0 0 0 8px;
    width: 100%;
    float: left;
}

.cart-page #menu .menu {
    width: 80px;
    display: flex;
    white-space: nowrap;
    font-size: 14px;
}

.cart-page #menu .lng {
    position: absolute;
    left: inherit;
    right: 0;
    top: 50%;
    overflow: inherit;
    transform: translateY(-50%);
    width: 120px;
    background: none;
    height: 26px;
}

.cart-page #menu .lng li.atual a {
    padding: 5px;
    position: relative;
}

.cart-page #menu .lng li a img {
    display: block;
    width: 14px;
    height: 11px;
    float: none;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.cart-page #menu .lng li p {
    text-align: center;
    margin: 0px;
}

.cart-page #menu .lng li.atual a .fa {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bolder;
    color: #737373;
}

.cart-page #menu .lng li a:hover {
    background: none;
}

.cart-page #menu .lng li a:hover p,
.cart-page #menu .lng li a:hover .fa.fa-angle-down{
    color: #91EC3D;
}

.cart-page #menu .lng li a .fa.fa-angle-down{
    right: 5px;
}

.cart-page #menu .lng.open .lng-opt {
    background: #232323;
}

.cart-page #menu .lng.open .lng-opt:last-child {
    border-radius: 0 0 4px 4px;
}

.cart-page #menu .lng.open .lng-opt a:hover {
    background: rgba(255,255,255,.04);
}

.cart-page #menu .lng li.lng-opt a {
    position: relative;
}

.cart-page #menu .lng.open .lng-opt img {
    left: 5px;
    margin: 0px;
}

.cart-page #header {
    background: url('../img/header_bg_cart.png') center bottom no-repeat #151515;
}

.cart-page .cart-header{
    /*margin-bottom: 10px;*/
}

.cart-page .cart-header:before,
.cart-page .cart-header:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cart-page .cart-header:after {
    clear: both;
}

.cart-page .amb-seguro{
    float: left;
    width: 180px;
    text-shadow: 1px 1px 2px rgba(0,0,0,.4);
    /*line-height: 32px;*/
    font-size: 15px;
}

.cart-page .amb-seguro i{
    margin-right: 8px;
}

.cart-page .tit1{
    width: calc(100% - 360px);
    float: left;
    margin-bottom: 0px;
}

.cart-page .change-coin{
    position: relative;
    display: block;
    width: 180px;
    float: left;
}

.cart-page .change-coin .coin ul li a{
    position: relative;
    display: block;
    padding: 10px;
}

.cart-page .change-coin span{
    position: relative;
    display: block;
    float: left;
    line-height: 40px;
    text-shadow: 1px 1px 2px rgba(0,0,0,.4);
    font-size: 15px;
}

.cart-page .coin{
    position: relative;
    display: block;
    float: right;
    overflow: inherit;
    width: 120px;
    background: none;
    height: 40px;
    font-size: 14px;
}

.cart-page ul {
    position: relative;
}

.cart-page .roxin-elite-label{
    cursor: pointer;
}

.cart-page .coin li.atual {
    border-top: none;
    display: block;
    box-sizing: border-box;
    border: 1px solid #424242;
    border-radius: 4px;
    background: #151515;
}

.cart-page .coin li.atual a:before,
.cart-page .coin li.atual a:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cart-page .coin li.atual a:after {
    clear: both;
}

.cart-page .coin li.atual p {
    width: calc(100% - 9px);
    float: left;
    line-height: 20px;
    text-shadow: 1px 1px 2px rgba(0,0,0,.4);
}

.cart-page .coin li.atual i{
    float: left;
    line-height: 20px;
    text-shadow: 1px 1px 2px rgba(0,0,0,.4);
}

.cart-page .coin li {
    border-top: solid 1px rgba(255,255,255,.06);
    display: none;
}

.cart-page .coin li.coin-opt a {
    position: relative;
    text-shadow: 1px 1px 2px rgba(0,0,0,.4);
}

.cart-page .coin li.coin-opt a {
    padding-left: 5px;
    padding-right: 0;
    font-size: 10px;
    display: block;
}

.cart-page .coin li p {
    text-align: center;
    margin: 0px;
}

.cart-page .coin.open li.atual {
    display: block;
    border-radius: 4px 4px 0px 0px;
}

.cart-page .coin.open .coin-opt{
    display: block;
    margin: 0px;
    width: 100%;
    background: #0e0e0e;
    border: none;
    line-height: 40px;
    border-left: 1px solid #424242;
    border-right: 1px solid #424242;
    border-bottom: 1px solid #424242;
}

.cart-page .coin.open .coin-opt p {
    text-align: center;
    margin: 0px;
    font-size: 12px;
}

.cart-page .coin.open .coin-opt a {
    padding: 0px;
}

.cart-page .coin.open li:last-child {
    border-radius: 0px 0px 4px 4px;
}

.cart-page .plan-cover{
    text-align: center;
    margin-bottom: 6px;
}

.cart-page .plans .tit3{
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin: 0px 0 0 0;
    text-shadow: 1px 1px 2px rgba(0,0,0,.4);
}

.cart-page .plans .tit4{
    font-size: 13px;
    font-weight: 500;
    margin: auto;
    text-shadow: 1px 1px 2px rgba(0,0,0,.4);
    text-transform: initial;
    color: #91EC3D;
}
.cart-page .act-acc{
    cursor: pointer;
}

.cart-page .plan-price{
    position: relative;
    display: block;
    color: #91EC3D;
    line-height: 1.357em;
    font-size: 22px;
    text-shadow: 1px 1px 2px rgba(0,0,0,.4);
    text-align: center;
}

.cart-page .plan-price span{
    position: relative;
    margin: 0 auto;
    color: #737373;
    line-height: 1.357em;
    font-size: 14px;
    font-weight: 100;
}

.cart-page .form-select .form-control-mask {
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    position: relative;
}

.cart-page .form-select ul li {
    font-size: 12px;
    font-weight: 600;
}

.cart-page .payments-menu .form-select ul li{
    font-size: 14px;
    font-weight: 400;
}

.modal-process .fechar{
    display: none;
}

.cart-page .roxpin-enable.hide{
    display: none;
}

.cart-page .form-select ul{
    position: absolute;
}

.cart-page .form-checks{
    border: 1px solid #424242;
    border-radius: 5px;
    background: #151515;
    width: 100%;
    padding: 14px 29px 15px 29px;
}

.cart-page .form-checks .roxpin{
    float: left;
    font-size: 15px;
    font-weight: 500;
    color: #91EC3D;
    line-height: 1.357em;
    font-size: 14px;
    padding-left: 66px;
    text-transform: uppercase;
    background: url("../img/roxin.png") no-repeat center left 20px;
}

.cart-page .final-cart .class_checkbox{
    margin-right: 10px;
}

.cart-page .form-checks .checkbox{
    float: left;
    font-size: 15px;
    font-weight: 500;
    color: #91EC3D;
    line-height: 1.357em;
    font-size: 14px;
    text-transform: uppercase;
}

.cart-page .form-checks .checkbox span{
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: #737373;
    line-height: 1.357em;
    font-size: 12px;
}

.cart-page .form-checks .roxpin span{
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: #737373;
    line-height: 1.357em;
    font-size: 12px;
}

.cart-page .form-checks:before,
.cart-page .form-group:before,
.cart-page .form-checks:after,
.cart-page .form-group:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cart-page .form-checks:after,
.cart-page .form-group:after {
    clear: both;
}

.cart-page .form-control{
    box-shadow: none;
    text-shadow: none;
}

.cart-page .form-mth{
    float: left;
    width: 30%;
    padding-right: 5px;
}
.cart-page .form-yer{
    float: left;
    width: 30%;
    padding-left: 5px;
}

.cart-page .form-ccv{
    float: left;
    width: 25%;
    padding-right: 5px;
}
.cart-page .form-zip{
    float: left;
    width: 40%;
    padding-left: 5px;
}

.cart-page .class_checkbox {
    width: 20px;
    height: 20px;
    background: url(../img/cart/check.png) no-repeat center center;
    float: left;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 43px;
    margin-right: -7px;
}

.cartbt{
    width: 32.5%;
}

.warn{
    width: 49% !important;
}

input:focus, textarea:focus, select:focus{
    outline: none;
}
@media screen and (max-width: 767px) {
    .coin{
        margin: auto;
        float: none !important;
    }

    .planschecks .class_checkbox {
        margin-left:  20%;
    }


    .plansoptions{
        position: relative;
        display: block;
        overflow: inherit;
        background: none;
        height: 40px;
        font-size: 14px;
    }

    .plansoptions li {
        border-top: solid 1px rgba(255,255,255,.06);
        display: none;
    }

    .plansoptions li.atual {
        border-top: none;
        display: block;
        box-sizing: border-box;
        border: 1px solid #424242;
        border-radius: 4px;
        background: #151515;
    }

    /*.plansoptions .others p {
        text-align: center;
        margin: 0px;
    }*/

    .plansoptions ul li a {
        position: relative;
        display: block;
        padding: 10px;
    }

    .plansoptions li.atual p {
        width: calc(100% - 9px);
        float: left;
        line-height: 20px;
        text-shadow: 1px 1px 2px rgba(0,0,0,.4);
    }
    
    .plansoptions li p {
        text-align: center;
        margin: 0px;
        color: #91EC3D;
        font-size: 14px;
        text-transform: uppercase;
        line-height: 20px;
        letter-spacing: -0.5px;
        font-weight: 500 !important;
    }
    .plansoptions li.atual {
        line-height: 20px;
        text-shadow: 1px 1px 2px rgba(0,0,0,.4);
    }

    .plansoptions.open .others {
        display: block;
        margin: 0px;
        width: 100%;
        background: #0e0e0e;
        border: none;
        line-height: 40px;
        border-left: 1px solid #424242;
        border-right: 1px solid #424242;
        border-bottom: 1px solid #424242;
    }

    .cartbt {
        width: 80% !important;
    }

    .warn {
        width: 80% !important;
    }


    .plansoptions.open .others {
        display: block;
        margin: 0px;
        width: 100%;
        background: #0e0e0e;
        border: none;
        line-height: 20px;
        border-left: 1px solid #424242;
        border-right: 1px solid #424242;
        border-bottom: 1px solid #424242;
    }

    .cart-page .plan-detail {
        margin: 0 10% 0 10%;
    }

    .whatis-roxpin{
        margin: 40px 10% 0 10%;
        width: 80%;
    }

    .whatis-roxpin p{
        text-align: left !important;
    }
}

.whatis-roxpin{
    position: relative;
    display: block;
    margin-top: 52px;
    text-align: center;
}

.cart-page .class_checkbox.checked {
    background: url("../img/cart/checked.png") no-repeat center center;
}

.cart-page .cep-text{
    line-height: 35px;
    text-align: left;
    text-shadow: 1px 1px 2px rgba(0,0,0,.4);
    font-size: 15px;
}

.cart-page .cep-input{
    padding-right: 0px;
    text-shadow: 1px 1px 2px rgba(0,0,0,.4);
}

.cart-page .frete{
    text-align: center;
    font-size: 14px;
}

.cart-page .frete span{
    font-weight: 500;
}
/*
.cart-page .plan-elite{
    padding-right: 20px;
}
*/

.cart-page .bonus-list{
    width: 100%;
    max-width: 150px;
    margin: 12px auto 0 auto;
}

.cart-page .bonus-list li{
    font-size: 13px;
}

.cart-page .bonus-list li i{
    color: #91EC3D;
    font-size: 20px;
    font-weight: 100;
}

.cart-page .plan-detail{
    position: relative;
    display: block;
    margin-top: 10px;
}

.cart-page .plan-detail span{
    width: 100%;
    position: relative;
    display: block;
    text-align: center;
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: 500;
}

.cart-page .plan-detail ul li{
    font-size: 13px;
    margin-bottom: 8px;
}

.cart-page .plan-detail ul li i{
    color: #91EC3D;
    font-weight: 100;
    margin-right: 6px;
}

.cart-page .whatis-roxpin span{
    position: relative;
    display: block;
    width: 100%;
    max-width: 185px;
    background: url("../img/roxin-gray.png") no-repeat left center;
    padding-left: 26px;
    margin: 35px auto 0 auto;
    font-size: 14px;
}

.cart-page .whatis-roxpin p{
    position: relative;
    display: block;
    width: 100%;
    margin: 80px 0 0 0;
    font-size: 12px;
}

.cart-page .whatis-roxpin p:last-child{
    margin: 0px;
}

.cart-page .esq{
    font-size: 22px;
    float: left;
}

.cart-page .tit2.esq{
    font-size: 22px;
    float: left;
    margin-top: 15px;
}

.cart-page .tit2 + img{max-width: 100%;}

.cart-page .register {
    border-top: none;
    display: block;
    padding: 8px 0px;
    box-sizing: border-box;
    border: 1px solid #424242;
    border-radius: 4px;
    background: #151515;
    font-size: 13px;
    text-align: center;
    margin-top: 15px;
}

.cart-page .buy-now {
    border-top: none;
    display: block;
    padding: 12px 0px 11px 0px;
    box-sizing: border-box;
    border: 1px solid #424242;
    border-radius: 4px;
    background: #151515;
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    margin-top: 27px;
}

.cart-page .final-cart label{
    font-size: 14px;
    margin-bottom: 10px;
    position: relative;
    display: block;
    text-shadow: 1px 1px 2px rgba(0,0,0,.4);
}



.cart-page .register:hover,
.cart-page .buy-now:hover {
    border-color: #989898;
    background: #303030;
    color: #bababa;
}

.cart-page .checkout .payments-menu{
    border: 3px solid transparent;
    border-top: none;
    display: block;
    padding: 13px 30px;
    box-sizing: border-box;
    border: 1px solid #424242;
    border-radius: 5px;
    background: #151515;
    text-align: center;
    margin-bottom: 20px;
}

.cart-page .checkout .payments-menu:before,
.cart-page .checkout .payments-menu:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cart-page .checkout .payments-menu:after {
    clear: both;
}

.cart-page .checkout .payments-menu:hover{
    border: 3px solid #747474;
}

.cart-page .checkout p {
    text-align: center;
    font-size: 13.5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.cart-page .checkout .payments-menu h3{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.cart-page .checkout .payments-menu .extra{
    height: 0px;
    overflow: hidden;
    width: 100%;
    clear: both;
}

.cart-page .checkout .payments-menu .center-content hr{
    border-top: #444444 1px solid;
    max-width: 100%;
    margin: 15px auto;
}

.cart-page .checkout .payments-menu label,
.cart-page .checkout .payments-menu input{
    text-align: left;
}

.cart-page .checkout .payments-menu .form-checks{
    border: none;
    border-radius: 0;
    background: none;
    padding: 0;
    margin: 0 auto;
    float; none;
    width: 100%;
    /*max-width: 300px;*/
}

.cart-page .form-checks label{
    display: inline-block;
}

.cart-page .form-checks .btn{
    width: 50%;
}

.cart-page .checkout .total-price{
    position: relative;
    display: block;
    font-size: 15px;
    color: #91EC3D;
    text-align: center;
    margin: 10px 0;
    text-shadow: 1px 1px 2px rgba(0,0,0,.4);
}

.cart-page .checkout .termos{
    position: relative;
    display: block;
    font-size: 13px;
    text-align: center;
    margin: 10px 0;
    text-shadow: 1px 1px 2px rgba(0,0,0,.4);
}

.cart-page .checkout .active {
    border: 3px solid #747474;
}

.cart-page .checkout .active .extra{
    display: block;
    height: auto;
}

.cart-page .checkout .active .form-select .form-control-mask{
    font-size: 14px;
    font-weight: 400;
}

.cart-page .plans .form-group input  {
    box-shadow: inset 2px 2px 3px rgba(0,0,0,.6) !important;
    text-shadow: 1px 1px 2px rgba(0,0,0,.4) !important;
}


@media screen and (min-width: 600px) {
    #first-screen .plans .form-group{
        width: 83%;
        position: relative;
        left: 50%;
        -webkit-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        transform: translateX(-50%);
    }

    .planschecks{
        width: 120px;
    }

    .planschecks div{
        width: 130px;
        padding-left: 0px;
        padding-right: 10px;
        padding-top: 0px;
        margin: auto;
        color: #91EC3D;
        font-size: 14px;
        text-transform: uppercase;
        line-height: 20px;
        letter-spacing: -0.5px;
        font-weight: 500 !important;
    }
}
@media screen and (max-width: 767px) {
    #first-screen .plans .form-group {
        width: 80%;
        position: relative;
        left: 50%;
        -webkit-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        transform: translateX(-50%);
        text-align: center;
        z-index: 10;
    }

    .planschecks{
        width: 100%;
    }
    
    .planschecks div{
        width: 50%;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        color: #91EC3D;
        font-size: 13px;
        text-transform: uppercase;
        line-height: 24px;
        letter-spacing: 0.5px;
    }
}


.planschecks{
    display: inline-flex;
    padding: 7px 0px;
    cursor: pointer;
    font-weight: 700 !important;
    border: solid 1px #444444;
    background: #151515;
    border-radius: 5px;
    cursor: pointer;
    width: 19%;
    margin: 0px 0.5% 0 0.5%;
}



.cart-page .modal-cart .modal-content{
    text-align: center;
}

.cart-page .modal-cart .modal-content h2{
    text-align: center;
    margin: 20px auto 15px auto;
    font-size: 22px;
}

.cart-page .modal-cart .modal-content p{
    font-size: 14px;
}

.cart-page .modal-success .janela{
    box-shadow: 0 0 8px 3px rgba(146,237,62,.7) !important;
}

.cart-page .modal-error .janela{
    box-shadow: 0 0 8px 3px rgba(185,55,55,.7) !important;
}

.cart-page .modal-cart .janela{
    background: #1c1c1c !important;
}

.cart-page .modal-error  .modal-content h2{
    color: #b93737;
}

.cart-page .modal-success .modal-content .register.fechar{
    position: relative !important;
    top: inherit !important;
    right: inherit !important;
    max-width: 240px;
    margin: 0 auto;
}

.cart-page .modal .overlay {
    height: 100vh;
    z-index: 3001;
    background: rgba(0,0,0,.4	);
}

@media screen and (max-width: 767px){
    .cart-page .amb-seguro {
        position: relative;
        display: block;
        float: none;
        width: 100%;
        font-size: 16px;
        text-align: center;
        margin: 20px 0 20px 0;
    }

    .cart-page #menu {
        padding: 0px;
        float: none;
        margin: 0px;
        width: 100%;
        float: none;
        position: relative;
        display: block;
    }

    .cart-page #menu:before,
    .cart-page #menu:after {
        content: " "; /* 1 */
        display: table; /* 2 */
    }

    .cart-page #menu:after {
        clear: both;
    }

    .cart-page #menu .menu {
        width: 70px;
        display: flex;
        white-space: nowrap;
        font-size: 14px;
        float: left;
        position: relative;
        display: block;
        top: inherit;
        right: inherit;
        margin-top: 15px;
        margin-bottom: 10px;
        border: none;
        background: none;
    }

    .cart-page #menu .menu>li>a {
        display: block;
        line-height: 40px;
        border: none;
        padding: 0px;
    }

    .cart-page #header{
        background: url(../img/header_bg_cart.png) center bottom no-repeat #151515;
        border: none;
    }
    .cart-page #logo {
        height: 100%;
    }

    .cart-page #logo img{
        height: 100%;
    }

    .cart-page .tit1 {
        width: 100%;
        float: none;
        margin-bottom: 20px;
    }

    .cart-page .change-coin {
        position: relative;
        display: block;
        width: 180px;
        float: none;
        margin: 50px auto 0 auto;
    }

    .cart-page .cart-header {
        margin-bottom: 30px;
    }

    .cart-page .plan-elite-vip{
        margin-top: 50px;
    }

    .cart-page .envio-roxpin{
        width: 100%;
    }

    .cart-page #menu .lng li p {
        display: none;
    }

    .cart-page #menu .lng {
        width: 50px;
    }

    .cart-page #menu .lng.open .lng-opt {
        padding: 10px 0;
    }

    .cart-page #menu .lng li.atual{
        padding: 10px 0;
    }

    .cart-page .change-coin{
        z-index: 100;
    }

    .cart-page .tit2.esq{
        width: 100%;
        float: none;
    }
}
.coin li{
    cursor: pointer;
}

span.errormsg {
    color: #f96458;
    background: #710d0d;
    font-size: 13px;
    padding: 10px;
    display: block;
    border: 1px solid #a71b1b;
    border-radius: 5px;
    margin-bottom: 10px;
}
span.errormsg img {
    margin-right: 5px;
}

.jp-card-unknown.jp-card .jp-card-front,
.jp-card-unknown.jp-card .jp-card-back {
    background: #2d5800 !important;
}

.jp-card-container {
    margin: 15px auto;
}

@media (max-width: 767px) {
    .jp-card-container {display: none;}
}

span.cupommsg {
    color: #91ec3d;
    background: #151515;
    font-size: 13px;
    padding: 10px;
    display: block;
    border: 1px solid #232323;
    border-radius: 5px;
    margin-top: 10px;
}

.trocarconta {
    border-top: none;
    display: block;
    padding: 8px 0px;
    box-sizing: border-box;
    border: 1px solid #ff4016;
    border-radius: 4px;
    background: #170000;
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    margin-top: 15px;
    color: #ff4016;
}

.trocarconta:hover {
    background: #2f0101;
    color: #fff;
}

.jatemconta {
    border-top: none;
    display: block;
    padding: 8px 0px;
    box-sizing: border-box;
    border: 1px solid #92ed3e;
    border-radius: 4px;
    background: #151515;
    font-size: 13px;
    text-align: center;
    margin-top: 15px;
    color: #92ed3e;
}

.jatemconta:hover {
    color: #fff;
    background: #093000;
}

.escolhaciclo {
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    position: relative;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.middle-line-label{
    line-height: 37px;
    float: left;
}

.parameses {
    font-size: 13px;
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
}

.asterisco-price {
    font-size:16px;

}
.span-desconto {
    color: #fec501;
    background: #1c1c1c;
    font-size: 13px;
    padding: 15px;
    display: block;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid #fec501;
}

.col-lg-12.container-coupon {
    font-size: 14px;
}

#mp-pay {
    font-size: 14px;
}


.ie {
    width: 100% !important;
    float: none !important;
}

.iesub {
    text-align: center;
    text-transform: none;
    color: #91EC3D;
    margin: 20px 0 0px 0;
    font-size: 15px;
    font-weight: 600;
}

.pageie {
    background-color: #151515;
    padding: 20px 15px;
    margin-bottom: 20px;
    border: solid 1px #242424;
    border-radius: 5px;
    width: 222px;
    margin: 20px 10px;
    display: inline-block;
}


.pageie-img {
    float: left;
    position: relative;
    display: block;
    width: 50px;
    margin-right: 10px;
}

.pageie-img img {
    object-fit: cover;
    width: 100%;
}

.pageie .page-content {
    margin-left: 60px;
}

.pageie h3 {
    font-size: 20px;
    color: #91EC3D;
    margin-bottom: 10px;
    font-weight: bold;
    line-height: 1em;
    text-transform: none;
    cursor: pointer;
    float: none;
    text-align: left;
}

.pageie p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #ababab;
    float: none;
    text-align: left;
}