.btn-secondary-outline,
.btn-secondary-outline:active {
  background: none !important;
  border-color: #ffb509 !important;
  color: #ffb509 !important;
}

a,
a:hover {
  color: #ffb509 !important;
}

.text-uppercase-home {
  color: #ffb509 !important;
}

.cid-rrLmo3HcjB H2 {
  color: #ffb509 !important;
}

.cid-rrLqJ2eqKI H2 {
  color: #ffb509 !important;
}

.cid-rrLulrKYE0 H2 {
  color: #ffb509 !important;
}

.wt-text-green {
  color: #ffb509 !important;
}

.cart-page .bg-primary {
  background-color: #ffb509 !important;
}

.cart-page .form-checks .checkbox {
  color: #ffb509;
}

.cid-rrLWFnTHEl H2 {
  color: #ffb509;
}

.cid-rrM0LrwUqn H2 {
  color: #ffb509;
}

.cid-rrM1G55buQ H2 {
  color: #ffb509;
}

.cid-rrLWTHUVER table td {
  border-top: 1px solid #ffb509;
}

.cid-rrM2rZLA6O H2 {
  color: #ffb509;
}

.wt-table td {
  border-top: 1px solid #ffb509 !important;
}

.wt-table {
  border: 1px solid #ffb509 !important;
}

.btn-se condary,
.btn-secondary:active {
  background-color: #ffb509 !important;
  border-color: #ffb509 !important;
  color: #000000 !important;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary.active {
  background-color: #f0bd46 !important;
  border-color: #f0bd46 !important;
  color: #000000 !important;
}

.cid-rrLWTHUVER table {
  width: 100% !important;
  margin-top: 6px;
  border: 1px solid #f0bd46;
  margin-bottom: 0;
}
.btn-secondary-white:hover,
a.btn-secondary-white:not(.btn-form):hover,
a.btn-secondary-white:not(.btn-form):focus {
  background-color: #f0bd46 !important;
  border-color: #f0bd46 !important;
  color: #000;
}

.btn-secondary-outline:hover,
.btn-secondary-outline:focus,
.btn-secondary-outline.focus,
.btn-secondary-outline.active {
  color: #000000 !important;
  background-color: #f0bd46 !important;
  border-color: #f0bd46 !important;
}

.cartbtwhite {
  display: block;
  color: #f0bd46 !important;
  border: 2px solid #f0bd46 !important;
  background: #fff !important;
  border-radius: 5px;
}

.cartbtwhite:hover {
  color: #000 !important;
  background: #f0bd46 !important;
}

.cid-rrLSpuslof H2 {
  color: #f0bd46;
}

.tit1 {
  color: #f0bd46;
}

.btn {
  color: #f0bd46 !important;
  border: 2px solid #f0bd46 !important;
}

.btn-secondary,
.btn-secondary:active {
  background-color: #f0bd46 !important;
  border-color: #f0bd46 !important;
  color: #000000 !important;
}

.cartbt {
  color: #f0bd46 !important;
}

.tit1 > div .linha:after,
.tit2 > div .linha:after {
  background: #f0bd46;
}

.btn:hover {
  background: #f0bd46 !important;
}

.ovr-verde:hover,
.verde {
  color: #f0bd46 !important;
}

.alert-danger {
  border-color: #f0bd46 !important;
}

.green-panel {
  color: #f0bd46 !important;
}

#conta #painel-historico table th {
  color: #f0bd46 !important;
}

.btn-panel-bk {
  background-color: #f0bd46 !important;
}

.btn-panel {
  background-color: #f0bd46 !important;
  color: #000 !important;
  border: 2px solid #f0bd46 !important;
}

.title-link-panel {
  color: #f0bd46 !important;
}

.title-panel {
  color: #f0bd46 !important;
}

#conta #painel-vencimento table th {
  color: #f0bd46 !important;
}

.title-link-panel {
  color: #f0bd46 !important;
}

.btn-secondary-white,
.btn-secondary-white:active {
  background-color: #ffb509 !important;
  border-color: #ffb509 !important;
  color: #000000 !important;
}

.tit2,
.tit3 {
  color: #ffb509;
}

#conta .active {
  border-color: #ffb509;
  color: #ffb509;
}

.npctitle span {
  border: 2px solid #ffb509;
}

#conta .painel {
  background: transparent !important;
}

@media only screen and (max-width: 1200px) {
  .btn-small-res {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    margin-top: -3px !important;
  }

  .navbar-short .btn-small-res {
    margin-top: 8px !important;
  }
}

@media only screen and (max-width: 800px) {
  .btn-small-res {
    margin-top: 30px !important;
  }

  .navbar-short .btn-small-res {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 600px) {
  .lang a img {
    max-width: 35px;
  }
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) {
  .btn-small-res {
    margin-top: 60px !important;
  }
}