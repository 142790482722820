    /* Noty */
    
    .noty-wrapper {
        font-size: 14px;
        font-weight: bold;
        z-index: 10000;
        position: fixed !important;
        top:0 !important;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        -moz-opacity: 90;
        opacity: 0.9;
        filter: alpha(opacity=90);
    }
    .noty-wrapper .bg-black {
        background-color: #922c2c;
        color: #FFF;
    }
    .noty-wrapper .bg-green {
        background-color: #15700f;
        color: #FFF;
    }
    .noty-wrapper:hover {
        opacity: 1;
        -moz-opacity: 1;
        filter: alpha(opacity: 100);
    }
    .noty_message {
        line-height: 20px;
        padding: 15px 10px;
        text-align: center;
    }
    #noty_bottom {
        bottom: 0;
    }
    #noty_top {
        top: 0;
    }
    #noty_center {
        top: 50%;
        left: 50%;
    }
    #noty_center li {
        margin: 10px 0;
        border: 0;
    }
