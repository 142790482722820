
.npsprite {
  display: inline-block;
}
.npspriteb {
  display: block;
}
/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.npsprite {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.npsprite-afiliado {
  background-image: url(sprite.png);
  background-position: -32px -100px;
  width: 16px;
  height: 16px;
}
.npsprite-alert-red {
  background-image: url(sprite.png);
  background-position: -32px -68px;
  width: 20px;
  height: 20px;
}
.npsprite-br {
  background-image: url(sprite.png);
  background-position: -94px -68px;
  width: 20px;
  height: 14px;
}
.npsprite-depoimento {
  background-image: url(sprite.png);
  background-position: -16px -100px;
  width: 16px;
  height: 16px;
}
.npsprite-es {
  background-image: url(sprite.png);
  background-position: -52px -68px;
  width: 22px;
  height: 14px;
}
.npsprite-faixa_especial {
  background-image: url(sprite.png);
  background-position: 0px 0px;
  width: 63px;
  height: 68px;
}
.npsprite-faixa_normal {
  background-image: url(sprite.png);
  background-position: -63px 0px;
  width: 63px;
  height: 68px;
}
.npsprite-flag-afghanistan {
  background-image: url(sprite.png);
  background-position: -172px -132px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-albania {
  background-image: url(sprite.png);
  background-position: -204px -156px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-algeria {
  background-image: url(sprite.png);
  background-position: -192px -200px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-american-samoa {
  background-image: url(sprite.png);
  background-position: -176px -200px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-andorra {
  background-image: url(sprite.png);
  background-position: -172px -96px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-angola {
  background-image: url(sprite.png);
  background-position: 0px -152px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-anguilla {
  background-image: url(sprite.png);
  background-position: -16px -164px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-antarctica {
  background-image: url(sprite.png);
  background-position: -64px -164px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-antigua-and-barbuda {
  background-image: url(sprite.png);
  background-position: -204px -168px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-argentina {
  background-image: url(sprite.png);
  background-position: -48px -88px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-armenia {
  background-image: url(sprite.png);
  background-position: -64px -88px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-aruba {
  background-image: url(sprite.png);
  background-position: -80px -88px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-australia {
  background-image: url(sprite.png);
  background-position: -96px -88px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-austria {
  background-image: url(sprite.png);
  background-position: -95px -100px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-azerbaijan {
  background-image: url(sprite.png);
  background-position: 0px -116px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-bahrain {
  background-image: url(sprite.png);
  background-position: -16px -116px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-bangladesh {
  background-image: url(sprite.png);
  background-position: -32px -116px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-barbados {
  background-image: url(sprite.png);
  background-position: -48px -116px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-belarus {
  background-image: url(sprite.png);
  background-position: -64px -116px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-belgium {
  background-image: url(sprite.png);
  background-position: -80px -116px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-belize {
  background-image: url(sprite.png);
  background-position: -96px -116px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-benin {
  background-image: url(sprite.png);
  background-position: -112px -116px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-bermuda {
  background-image: url(sprite.png);
  background-position: 0px -128px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-bhutan {
  background-image: url(sprite.png);
  background-position: -16px -128px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-bolivia {
  background-image: url(sprite.png);
  background-position: -32px -128px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-bosnia-and-herzegovina {
  background-image: url(sprite.png);
  background-position: -48px -128px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-botswana {
  background-image: url(sprite.png);
  background-position: -64px -128px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-brazil {
  background-image: url(sprite.png);
  background-position: -80px -128px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-brunei {
  background-image: url(sprite.png);
  background-position: -96px -128px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-bulgaria {
  background-image: url(sprite.png);
  background-position: -112px -128px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-burkina-faso {
  background-image: url(sprite.png);
  background-position: -140px 0px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-cambodia {
  background-image: url(sprite.png);
  background-position: -140px -12px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-cameroon {
  background-image: url(sprite.png);
  background-position: -140px -24px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-canada {
  background-image: url(sprite.png);
  background-position: -140px -36px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-cape-verde {
  background-image: url(sprite.png);
  background-position: -140px -48px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-chad {
  background-image: url(sprite.png);
  background-position: -140px -60px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-chile {
  background-image: url(sprite.png);
  background-position: -140px -72px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-china {
  background-image: url(sprite.png);
  background-position: -140px -84px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-colombia {
  background-image: url(sprite.png);
  background-position: -140px -96px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-comoros {
  background-image: url(sprite.png);
  background-position: -140px -108px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-congo {
  background-image: url(sprite.png);
  background-position: -140px -120px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-cook {
  background-image: url(sprite.png);
  background-position: 0px -140px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-costa-rica {
  background-image: url(sprite.png);
  background-position: -16px -140px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-croatia {
  background-image: url(sprite.png);
  background-position: -32px -140px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-cuba {
  background-image: url(sprite.png);
  background-position: -48px -140px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-cyrus {
  background-image: url(sprite.png);
  background-position: -64px -140px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-czech-republic {
  background-image: url(sprite.png);
  background-position: -80px -140px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-denmark {
  background-image: url(sprite.png);
  background-position: -96px -140px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-dominican-republic {
  background-image: url(sprite.png);
  background-position: -112px -140px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-ecuador {
  background-image: url(sprite.png);
  background-position: -128px -140px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-egypt {
  background-image: url(sprite.png);
  background-position: -156px 0px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-el-salvador {
  background-image: url(sprite.png);
  background-position: -156px -12px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-empty {
  background-image: url(sprite.png);
  background-position: -156px -24px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-estonia {
  background-image: url(sprite.png);
  background-position: -156px -36px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-ethiopia {
  background-image: url(sprite.png);
  background-position: -156px -48px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-finland {
  background-image: url(sprite.png);
  background-position: -156px -60px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-france {
  background-image: url(sprite.png);
  background-position: -156px -72px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-gabon {
  background-image: url(sprite.png);
  background-position: -156px -84px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-gambia {
  background-image: url(sprite.png);
  background-position: -156px -96px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-georgia {
  background-image: url(sprite.png);
  background-position: -156px -108px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-germany {
  background-image: url(sprite.png);
  background-position: -156px -120px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-ghana {
  background-image: url(sprite.png);
  background-position: -156px -132px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-greece {
  background-image: url(sprite.png);
  background-position: -220px -45px;
  width: 16px;
  height: 11px;
}
.npsprite-flag-guatemala {
  background-image: url(sprite.png);
  background-position: -16px -152px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-guinea-eq {
  background-image: url(sprite.png);
  background-position: -32px -152px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-guinea {
  background-image: url(sprite.png);
  background-position: -48px -152px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-honduras {
  background-image: url(sprite.png);
  background-position: -64px -152px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-hong-kong {
  background-image: url(sprite.png);
  background-position: -80px -152px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-hungary {
  background-image: url(sprite.png);
  background-position: -96px -152px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-iceland {
  background-image: url(sprite.png);
  background-position: -112px -152px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-india {
  background-image: url(sprite.png);
  background-position: -128px -152px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-indonesia {
  background-image: url(sprite.png);
  background-position: -144px -152px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-iran {
  background-image: url(sprite.png);
  background-position: -172px 0px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-iraq {
  background-image: url(sprite.png);
  background-position: -172px -12px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-ireland {
  background-image: url(sprite.png);
  background-position: -172px -24px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-israel {
  background-image: url(sprite.png);
  background-position: -172px -36px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-italy {
  background-image: url(sprite.png);
  background-position: -172px -48px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-jamaica {
  background-image: url(sprite.png);
  background-position: -172px -60px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-japan {
  background-image: url(sprite.png);
  background-position: -172px -72px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-jordan {
  background-image: url(sprite.png);
  background-position: -172px -84px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-kenya {
  background-image: url(sprite.png);
  background-position: -220px 0px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-kiribati {
  background-image: url(sprite.png);
  background-position: -172px -108px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-kz {
  background-image: url(sprite.png);
  background-position: -172px -120px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-liberia {
  background-image: url(sprite.png);
  background-position: -220px -34px;
  width: 16px;
  height: 11px;
}
.npsprite-flag-lk {
  background-image: url(sprite.png);
  background-position: -172px -144px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-luxembourg {
  background-image: url(sprite.png);
  background-position: 0px -164px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-mexico {
  background-image: url(sprite.png);
  background-position: -32px -88px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-morocco {
  background-image: url(sprite.png);
  background-position: -32px -164px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-mozambique {
  background-image: url(sprite.png);
  background-position: -48px -164px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-new-zealand {
  background-image: url(sprite.png);
  background-position: -79px -100px;
  width: 16px;
  height: 13px;
}
.npsprite-flag-nicaragua {
  background-image: url(sprite.png);
  background-position: -80px -164px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-niger {
  background-image: url(sprite.png);
  background-position: -96px -164px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-nigeria {
  background-image: url(sprite.png);
  background-position: -112px -164px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-north-korea {
  background-image: url(sprite.png);
  background-position: -128px -164px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-norway {
  background-image: url(sprite.png);
  background-position: -144px -164px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-pakistan {
  background-image: url(sprite.png);
  background-position: -160px -164px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-palestine {
  background-image: url(sprite.png);
  background-position: 0px -176px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-paraguay {
  background-image: url(sprite.png);
  background-position: -16px -176px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-peru {
  background-image: url(sprite.png);
  background-position: -32px -176px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-philippines {
  background-image: url(sprite.png);
  background-position: -48px -176px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-poland {
  background-image: url(sprite.png);
  background-position: -64px -176px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-portugal {
  background-image: url(sprite.png);
  background-position: -80px -176px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-puerto-rico {
  background-image: url(sprite.png);
  background-position: -96px -176px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-romania {
  background-image: url(sprite.png);
  background-position: -112px -176px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-russia {
  background-image: url(sprite.png);
  background-position: -128px -176px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-rwanda {
  background-image: url(sprite.png);
  background-position: -144px -176px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-sahara {
  background-image: url(sprite.png);
  background-position: -160px -176px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-saudi-arabia {
  background-image: url(sprite.png);
  background-position: -188px 0px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-sb {
  background-image: url(sprite.png);
  background-position: -188px -12px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-sc {
  background-image: url(sprite.png);
  background-position: -188px -24px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-senegal {
  background-image: url(sprite.png);
  background-position: -188px -36px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-serbia {
  background-image: url(sprite.png);
  background-position: -188px -48px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-sierra-leone {
  background-image: url(sprite.png);
  background-position: -188px -60px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-singapore {
  background-image: url(sprite.png);
  background-position: -188px -72px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-slovakia {
  background-image: url(sprite.png);
  background-position: -188px -84px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-slovenia {
  background-image: url(sprite.png);
  background-position: -188px -96px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-sm {
  background-image: url(sprite.png);
  background-position: -188px -108px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-somalia {
  background-image: url(sprite.png);
  background-position: -188px -120px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-south-africa {
  background-image: url(sprite.png);
  background-position: -188px -132px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-south-korea {
  background-image: url(sprite.png);
  background-position: -188px -144px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-spain {
  background-image: url(sprite.png);
  background-position: -188px -156px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-spm {
  background-image: url(sprite.png);
  background-position: -188px -168px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-st {
  background-image: url(sprite.png);
  background-position: 0px -188px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-sudan {
  background-image: url(sprite.png);
  background-position: -16px -188px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-suriname {
  background-image: url(sprite.png);
  background-position: -32px -188px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-swaziland {
  background-image: url(sprite.png);
  background-position: -48px -188px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-sweden {
  background-image: url(sprite.png);
  background-position: -64px -188px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-syria {
  background-image: url(sprite.png);
  background-position: -80px -188px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-taiwan {
  background-image: url(sprite.png);
  background-position: -96px -188px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-tc {
  background-image: url(sprite.png);
  background-position: -112px -188px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-tg {
  background-image: url(sprite.png);
  background-position: -128px -188px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-thailand {
  background-image: url(sprite.png);
  background-position: -144px -188px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-thebahamas {
  background-image: url(sprite.png);
  background-position: -160px -188px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-tj {
  background-image: url(sprite.png);
  background-position: -176px -188px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-tl {
  background-image: url(sprite.png);
  background-position: -204px 0px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-togo {
  background-image: url(sprite.png);
  background-position: -204px -12px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-tokelau {
  background-image: url(sprite.png);
  background-position: -204px -24px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-trinidad-and-tobago {
  background-image: url(sprite.png);
  background-position: -204px -36px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-tunisia {
  background-image: url(sprite.png);
  background-position: -204px -48px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-turkey {
  background-image: url(sprite.png);
  background-position: -204px -60px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-turkmenistan {
  background-image: url(sprite.png);
  background-position: -204px -72px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-tuvalu {
  background-image: url(sprite.png);
  background-position: -204px -84px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-tz {
  background-image: url(sprite.png);
  background-position: -204px -96px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-uganda {
  background-image: url(sprite.png);
  background-position: -204px -108px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-ukraine {
  background-image: url(sprite.png);
  background-position: -204px -120px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-united-arab-emirates {
  background-image: url(sprite.png);
  background-position: -204px -132px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-united-kingdom {
  background-image: url(sprite.png);
  background-position: -204px -144px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-uruguay {
  background-image: url(sprite.png);
  background-position: -220px -23px;
  width: 16px;
  height: 11px;
}
.npsprite-flag-usa {
  background-image: url(sprite.png);
  background-position: -220px -12px;
  width: 16px;
  height: 11px;
}
.npsprite-flag-uzbekistan {
  background-image: url(sprite.png);
  background-position: -204px -180px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-vanuat {
  background-image: url(sprite.png);
  background-position: 0px -200px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-vc {
  background-image: url(sprite.png);
  background-position: -16px -200px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-venezuela {
  background-image: url(sprite.png);
  background-position: -32px -200px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-vietnam {
  background-image: url(sprite.png);
  background-position: -48px -200px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-virgin-british {
  background-image: url(sprite.png);
  background-position: -64px -200px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-virgin-us {
  background-image: url(sprite.png);
  background-position: -80px -200px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-wallis {
  background-image: url(sprite.png);
  background-position: -96px -200px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-ws {
  background-image: url(sprite.png);
  background-position: -112px -200px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-yemen {
  background-image: url(sprite.png);
  background-position: -128px -200px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-zambia {
  background-image: url(sprite.png);
  background-position: -144px -200px;
  width: 16px;
  height: 12px;
}
.npsprite-flag-zimbabwe {
  background-image: url(sprite.png);
  background-position: -160px -200px;
  width: 16px;
  height: 12px;
}
.npsprite-home_seta_dir {
  background-image: url(sprite.png);
  background-position: -16px -68px;
  width: 16px;
  height: 32px;
}
.npsprite-home_seta_esq {
  background-image: url(sprite.png);
  background-position: 0px -68px;
  width: 16px;
  height: 32px;
}
.npsprite-informacoes {
  background-image: url(sprite.png);
  background-position: -64px -100px;
  width: 15px;
  height: 15px;
}
.npsprite-recorrencias {
  background-image: url(sprite.png);
  background-position: 0px -100px;
  width: 16px;
  height: 16px;
}
.npsprite-revenda {
  background-image: url(sprite.png);
  background-position: -48px -100px;
  width: 16px;
  height: 16px;
}
.npsprite-suporte {
  background-image: url(sprite.png);
  background-position: -126px 0px;
  width: 14px;
  height: 14px;
}
.npsprite-us {
  background-image: url(sprite.png);
  background-position: -74px -68px;
  width: 20px;
  height: 14px;
}
