#affiliate_screen{

}
#affiliate_screen h1{
	  color: #92ed3e;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 42px;
    letter-spacing: 1px;
    margin-top: 5%;
    line-height: 40px;
}

#affiliate_screen p{
    font-size: 20px;
    width: 330px;
    line-height: 23px;
    color: #bbbbbb;
    margin-bottom: 19px;
}

#affiliate_screen a{
    font-size: 18px;
    padding: 24px 41px;
    letter-spacing: 0.35px;
}

#affiliate_screen div{
    font-size: 16px;
    line-height: 18px;
    color: #bbbbbb;
}



#affiliate_ttl{
    background: #0e220e;
    height: 90px;
    border-color: #134713;
    border-width: 1px;
    border-style: solid;
}
#affiliate_ttl h2{
    text-align: center;
    line-height: 90px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 23px;
    color: #92ed3e;
}



#affiliate_intro{
    height: 344px;
}
#affiliate_intro h1{
    margin-left: 8%;
}
#affiliate_intro p{
    margin-left: 80px;
    margin-top: 15px;
}
#affiliate_intro a{
    margin-left: 82px;
}
#affiliate_intro img{
    position: absolute;
    bottom: -65px;
}



#affiliate_how{
    background: #0a0b2f;
    padding-bottom: 80px;
}

#affiliate_how iframe{
    position: absolute;
    bottom: 0;
    left: 90px;
}

#affiliate_how h1{
    margin-left: 510px;
    margin-top: 66px;
}
#affiliate_how .container div{
    margin-left: 510px;
    margin-top: 32px;

}
#affiliate_how .container div span{
    color: #002fbe;
    font-weight: 600;
    font-size: 43px;
}



#affiliate_why{
    height: 400px;
}
#affiliate_why h1{
    text-align: center;
    margin-top: 62px;
    margin-bottom: 47px;
}
#affiliate_why .container .whys {
    width: 326px;
    display: inline-block;
    height: 110px;
}
#affiliate_why .container .whys img {
    position: relative;
    left: 7px;
    top: -17px;
}
#affiliate_why .container .whys div {
    display: inline-block;
    width: 240px;
    margin-left: 20px;
    line-height: 22px;
}
#affiliate_why .container .whys div span {
    text-transform: uppercase;
    font-weight: 600;
    color: #92ed3e;
}



#affiliate_lvl{
    background: #151515;
    padding-bottom: 40px;
}
#affiliate_lvl .container{
    max-width: 1040px;
    width: 1040px;
}
#affiliate_lvl h1{
    text-align: center;
    margin-top: 74px;
    margin-bottom: 47px;
}
#affiliate_lvl .container .lvls {
    width: 326px;
    display: inline-block;
    margin: 5px 7px;
    padding: 10px 0px;
}
#affiliate_lvl .container .lvls:nth-of-type(1){top:160px;left: 180px; margin-left: 190px;}
#affiliate_lvl .container .lvls:nth-of-type(2){top:160px;left: 520px;}
#affiliate_lvl .container .lvls:nth-of-type(3){top: 300px;left: 0px}
#affiliate_lvl .container .lvls:nth-of-type(4){top: 300px;left: 340px}
#affiliate_lvl .container .lvls:nth-of-type(5){top: 300px;left: 680px}
#affiliate_lvl .container .lvls img {
    position: relative;
    top: -30px;
}
#affiliate_lvl .container .lvls div {
    display: inline-block;
    width: 240px;
    margin-left: 10px;
    line-height: 22px;
}
#affiliate_lvl .container .lvls div span {
    text-transform: uppercase;
    font-weight: 600;
    color: #92ed3e;
}
#affiliate_lvl .container .lvls p{
    color: #707070;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    bottom: 14px;
}



#affiliate_qtn{
    height: auto;
    padding-bottom: 60px;
}
#affiliate_qtn h1{
    text-align: center;
    margin-top: 64px;
    margin-bottom: 23px;
}
#affiliate_qtn .qtns{
    margin: auto;
    width: 656px;
    height: 50px;
    background: #061e65;
    border-color: #002fbe;
    border-style: solid;
    border-width: 1px;
    margin-top: 10px;
    cursor: pointer;
}
#affiliate_qtn .qtns div{
    margin: 0px 28px;
    line-height: 45px;
}

#affiliate_qtn .qtns div span{
    margin: 0px 0px;
    line-height: 45px;
    color: #92ed3e;
    font-size: 15px;
    font-weight: 600;
}

#affiliate_qtn .qtns div img{
	position: relative;
	top: 20px;
}

#affiliate_qtn .qtns p{
	display: block;
	margin: 0px 28px !important;
    width: auto;
    font-size: 15px;
}



#affiliate_bgn{
    height: 272px;
    background: #151515;
}
#affiliate_bgn h1{
    text-align: center;
    margin-top: 60px;
    margin-bottom: 20px;

}
#affiliate_bgn a{
    margin: auto;
    display: block;
    text-align: center;
    width: 320px;
    padding: 24px 21px;
}