#gametitle{
	margin-top: 40px;
    margin-bottom: 12px;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.4px;
}

#left_game{
    margin-left: -5px;
}

#left_game h1{
	margin-top: 25px;
    margin-left: 14px;
    margin-bottom: 26px;
    font-weight: 500;
}

#left_game p{
    margin-left: 14px;
    margin-bottom: -7px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding-right: 10px;
}

#left_game b{
	color: #bbbbbb;
}

.img_func{
    margin-left: 13px;
    margin-top: 5px;
    margin-bottom: 25px;
}

#left_game button{
    padding: 24px 50px;
    width: auto;
    margin: auto;
    margin-left: 136px;
    margin-top: 44px;
    font-size: 18px;
    letter-spacing: 0.3px;
}



#right_game{
    background: #151515;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #242424;
    margin-left: 12px;
    width: 323px;
}

#right_game figure{
    width: 240px;
    height: 335px;
    margin: auto;
    margin-top: 42px;
}

#right_game figure div{
	position: absolute;
    top: 42px;
    width: 240px;
    height: 335px;
    background: url(../img/gamepage/cover_border.png);
}

#right_game p{
    margin: 35px 20px;
    line-height: 22px;
    margin-bottom: 14px;
    font-size: 14px;
    letter-spacing: 0.3px;
}

#right_game b{
    text-transform: uppercase;
    font-size: 14px;
    color: #92ed3e;
    font-weight: 500;
}

#right_game hr{
    border-top: 1px solid #242424;
    margin: 14px 18px;
}

#right_game .sidefooter{
    margin-top: 0;
    font-size: 13px;
    letter-spacing: 0.44px;
    font-style: italic;
    line-height: 18px;
    margin-bottom: 33px !important;
}

