/*! HTML5 Boilerplate v4.3.0 - INICIO */

button,
html,
input,
select,
textarea {
    color: #222
}

html {
    -webkit-tap-highlight-color: transparent;
    font-size: 1em;
    line-height: 1.4;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important
}

.cart-page,
body {
    font: 100% "Maven Pro", Arial, Helvetica, sans-serif
}

::-moz-selection,
::selection {
    background: #b3d4fc;
    text-shadow: none
}

.cart-page .amb-seguro,
.form-control,
.form-control-blog {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .4)
}

hr {
    display: block;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0
}

.form-file input[type=file],
.form-select select {
    top: 0;
    height: 40px;
    cursor: pointer;
    left: 0;
    opacity: 0
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0
}

body {
    /*@durvalpcnbackground:url(../img/bg_01.jpg) center top no-repeat fixed #0c0c0c*/
    ;
    font-size: 14px;
    line-height: 1.143em;
    color: #737373
}

a,
a:active,
a:focus,
a:hover {
    color: inherit;
    text-decoration: none
}

a,
button,
input {
    outline: 0 !important
}

a,
a *,
button,
div,
img,
input,
span,
td {
    transition: color .2s, background-color .2s, border-color .2s, opacity .2s, box-shadow .2s
}

li,
ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    display: block;
    font-weight: 400
}

p {
    margin: 0 0 1.2em
}

p:last-child {
    margin-bottom: 0 !important
}

p small {
    font-size: .857em;
    line-height: inherit
}

.form-group {
    margin: 0 0 20px
}

.form-group .form-label {
    margin: 0 0 .2em;
    display: block
}

.form-control {
    border: 1px solid #424242;
    border-radius: 5px;
    background: #151515;
    padding: 10px 12px;
    line-height: 18px;
    font-size: 15px;
    width: 100%;
    color: #737373;
    box-shadow: inset 2px 2px 3px rgba(0, 0, 0, .6)
}

.form-control:focus {
    box-shadow: inset 2px 2px 3px rgba(0, 0, 0, .4), 0 0 4px #BFBABA;
    outline: 0
}

textarea.form-control {
    resize: none
}

.form-control.erro,
.form-control.ok {
    background-image: url(../img/form_erro.png);
    background-position: right 15px center;
    background-repeat: no-repeat;
    padding-right: 40px
}

.form-control.ok {
    background-image: url(../img/form_ok.png)
}

.form-checks label {
    line-height: 15px;
    white-space: nowrap;
    padding: 0;
    margin: 0 15px 5px 0;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    font-size: 12px
}

.form-checks label:last-child {
    margin-right: 0
}

.form-checks label input {
    position: absolute;
    visibility: hidden
}

.form-checks label span {
    display: block;
    line-height: inherit;
    position: relative;
    padding: 5px 0 5px 23px;
    background: url(../img/check-v1.png) left 5px no-repeat
}

.form-checks label input[type=radio]+span {
    background-image: url(../img/radio.png)
}

.form-checks label input:checked+span {
    background-position: left -127px
}

.form-select {
    position: relative;
    z-index: 300
}

.form-select.open {
    position: relative;
    z-index: 400
}

.form-select .form-control-mask {
    background-image: url(../img/select_caret.png);
    background-position: right center;
    background-repeat: no-repeat;
    overflow: hidden;
    padding-right: 20px;
    cursor: pointer;
    z-index: 311;
    white-space: nowrap;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none
}

.form-select.open .form-control-mask {
    border-radius: 5px 5px 0 0;
    border-bottom-color: transparent
}

.form-select select {
    z-index: 313;
    position: absolute;
    line-height: 40px;
    width: 100%
}

.desktop .form-select select {
    visibility: hidden
}

.form-select ul {
    position: absolute;
    border: 1px solid #424242;
    border-top: none;
    background: #151515;
    border-radius: 0 0 5px 5px;
    width: 100%;
    left: 0;
    top: 40px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .6);
    overflow: auto;
    display: none;
    max-height: 140px
}

.form-select ul::-webkit-scrollbar {
    width: 8px
}

.form-select ul::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    border-radius: 4px
}

.form-select ul::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .5);
    background: rgba(255, 255, 255, .1)
}

.form-select.open ul {
    display: block
}

.form-select ul li {
    cursor: pointer;
    line-height: 20px;
    padding: 10px 12px
}

.form-select ul li:hover {
    background: #1f1f1f
}

.form-select ul li.active {
    background: #242424 !important
}

.form-select.imgandtext ul {
    top: 86px
}

#choose_coin select {
    position: absolute;
    right: 20px;
    width: 120px
}

#header,
.container,
.form-file {
    position: relative
}

#choose_coin li {
    font-size: 15px;
    color: #737373
}

.form-file {
    z-index: 300
}

.form-file .form-control-mask {
    background-position: right center;
    background-repeat: no-repeat;
    overflow: hidden;
    padding-right: 20px;
    cursor: pointer;
    z-index: 311;
    white-space: nowrap;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    min-height: 40px
}

.form-file input[type=file] {
    z-index: 313;
    position: absolute;
    line-height: 40px;
    width: 100%
}

.form-file input[type=file].erro+.form-control-mask:after,
.form-file input[type=file].ok+.form-control-mask:after {
    position: absolute;
    z-index: 314;
    right: 18px;
    top: 0;
    height: 100%;
    opacity: 1;
    display: block;
    content: "";
    width: 16px
}

.form-file input[type=file].erro+.form-control-mask:after {
    background: url(../img/form_erro.png) center center no-repeat !important
}

.form-file input[type=file].ok+.form-control-mask:after {
    background: url(../img/form_ok.png) center center no-repeat !important
}

.btn,
.btn_w {
    width: 100%;
    text-align: center;
    font-size: 14px;
    padding: 9px 6px;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer
}

.btn,
.cartbt {
    display: block;
    color: #54ff00 !important;
    border: 2px solid #54ff00 !important;
    background: #151515 !important;
    border-radius: 5px
}


.cartbtwhite {
    display: block;
    color: #44BC50  !important;
    border: 2px solid #44BC50  !important;
    background: #FFF !important;
    border-radius: 5px;
}

.btn:hover,
.jatemconta {
    border: 1px solid #92ed3e
}

.btn:hover {
    color: #fff !important;
    background: #093000 !important
}

.cartbtwhite:hover {
    color: #000 !important;
    background: #44BC50 !important
}


.btn_w {
    display: block;
    color: #EC172E;
    border: 1px solid #ED091A;
    background: #151515;
    border-radius: 5px
}

.btn_w:hover {
    color: #fff;
    background: #FF0709
}

.btn-glow {
    box-shadow: inset 0 -3px 20px rgba(146, 237, 62, 1);
    background: #1d2912;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid
}

.btn-glow:hover {
    box-shadow: inset 0 -1px 20px rgba(146, 237, 62, 1), 0 0 5px rgba(146, 237, 62, 1)
}

.btn-inv {
    border-color: transparent !important;
    background: 0 0 !important
}

.btn-2 {
    border-color: #444 !important;
    background: #1c1c1c !important;
    color: #737373 !important;
    padding: 10px !important;
}

.btn-2:hover {
    border-color: #989898;
    background: #303030;
    color: #bababa
}

.btn-mini {
    padding: 4px !important;
    padding-bottom: 4px;
    font-size: 12px !important
}

.btn-mini.btn-glow {
    border-top: 5px;
    border-bottom: 5px
}

#planos .plano,
.img-border {
    border: 1px solid #292929
}

.btn-inline {
    display: inline-block;
    padding-left: 30px;
    padding-right: 30px;
    width: auto
}

.txt-btn {
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0
}

.txt-btn-mini {
    padding: 4px 0
}

.spacer {
    margin: 0 0 35px;
    line-height: 0;
    clear: both;
    height: 1px;
    width: 100%
}

.ovr-verde:hover,
.verde {
    color: #91EC3D !important
}

a.verde:hover {
    color: #fff !important
}

.bgverde,
.ovr-bgverde:hover {
    background-color: #91EC3D !important
}

.align-right {
    text-align: right
}

.align-justify {
    text-align: justify
}

.align-center {
    text-align: center
}

.align-left {
    text-align: left
}

#wrapper {
    width: 100%;
    margin: auto;
    overflow: hidden
}

#header {
    background: url(../img/header_bg.png) center bottom no-repeat #141414;
    border-bottom: solid 1px #2a2a2a;
    z-index: 1000
}

@media (max-width:767px) {
    #header {
        background: url(../img/header_bg.png) center bottom no-repeat #141414;
        border-bottom: solid 1px #2a2a2a;
        z-index: 1000;
        position: fixed;
        transition: top .1s ease-in-out;
        left: 0;
        top: 0
    }
    .nav-up {
        top: -90px !important
    }
}

#logo {
    padding: 12px 0 9px;
    float: left
}

#logo a,
#logo a img {
    display: block
}

#menu {
    position: relative;
    padding: 0 0 0 62px;
    float: left;
    margin: 0 0 0 8px
}

#menu .lng {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    background: #232323;
    border-radius: 0 0 5px 5px;
    overflow: hidden
}

#menu .lng li {
    border-top: solid 1px rgba(255, 255, 255, .06);
    display: none;
    padding-left: 5px
}

#menu .lng.open li {
    display: block
}

#menu .lng li a {
    display: block;
    padding: 5px
}

#menu .lng li a:hover {
    background: rgba(255, 255, 255, .04)
}

#menu .lng li a img {
    display: block;
    width: 14px;
    height: 11px
}

#menu .lng li.atual {
    border-top: none;
    display: block;
    margin-bottom: 8px
}

#menu .lng li.atual a {
    padding-top: 9px;
    padding-bottom: 9px
}

#menu .lng li.atual a .fa {
    position: absolute;
    right: 6px;
    top: 4px;
    color: #95EE3E;
    font-size: 18px
}

#menu .menu>li,
.tith,
.tith div {
    position: relative
}

#menu .lng li.lng-opt a {
    padding-left: 5px;
    padding-right: 0;
    font-size: 10px
}

#menu-mobile {
    line-height: 89px;
    font-size: 15px;
    color: #bababa;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer
}

#menu .menu {
    width: 440px;
    display: flex;
    white-space: nowrap;
    font-size: 14px
}

#menu .menu>li {
    display: block;
    flex: 1 1 auto
}

#menu .menu>li a {
    text-transform: uppercase;
    font-weight: 500;
    color: #BABABA
}

#menu .menu>li a:hover,
.downloadmenu ul li a:hover,
.submenu ul li a:hover {
    color: #91EC3D
}

#menu .menu>li>a {
    display: inline-block;
    line-height: 83px;
    border-top: solid 3px transparent;
    padding: 0 1px
}

#menu .menu>li.active>a {
    border-top-color: #91EC3E
}

#menu .menu>li>ul {
    display: none;
    position: absolute;
    left: -16px;
    top: 75px;
    border: 1px solid #444;
    border-radius: 5px;
    background: #1c1c1c;
    padding: 5px 0;
    z-index: 1050
}

#menu .menu>li>ul>li>a {
    display: block;
    line-height: 25px;
    padding: 0 15px
}

#header .botoes,
#header .logado {
    float: right;
    padding: 24px 0 0
}

#header .logado {
    text-align: right
}

#animacao .pager,
.downloadmenu ul,
.submenu ul,
.tith {
    text-align: center
}

#header .logado .user {
    display: inline-block;
    padding: 0 10px 0 0
}

#header .logado .btn {
    padding-left: 16px;
    padding-right: 16px
}

.submenu {
    background: #1B1B1B;
    display: none
}

.submenu ul {
    display: block;
    padding: 12px 0;
    line-height: 0
}

.submenu ul li {
    display: inline-block;
    margin: 0 15px
}

.submenu ul li a {
    display: block;
    line-height: 18px;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase
}

.downloadmenu {
    background: #1B1B1B;
    display: block
}

.downloadmenu ul {
    display: block;
    padding: 7px 0;
    line-height: 0;
    background-color: #141414
}

.downloadmenu ul li {
    display: inline-block;
    margin: 0 7px
}

.downloadmenu ul li a {
    display: block;
    line-height: 18px;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400
}

.tit1,
.tith,
.widgets h3 {
    font-size: 22px
}

.downloadmenu ul li .btn {
    padding: 5px 15px;
    text-transform: uppercase;
    width: 200px;
    text-shadow: 0 0 #000
}

#animacao .slides,
#animacao .slides .slide {
    width: 100%;
    overflow: hidden
}

#animacao .slides .slide a {
    display: block
}

#animacao .slides .slide img {
    display: block;
    width: 100%;
    margin: auto
}

#animacao .slides .slide img.mobile {
    display: none
}

#animacao .pager {
    z-index: 300;
    position: absolute;
    left: 0;
    bottom: 4px;
    width: 100%;
    line-height: 0;
    padding: 20px 0 20px 420px
}

#animacao .pager span {
    display: inline-block;
    margin: 0 6px;
    width: 12px;
    height: 12px;
    text-align: left;
    text-indent: -9999px;
    border-radius: 6px;
    background: #000;
    cursor: pointer
}

#animacao .pager span:hover {
    background: #23440E;
    box-shadow: 0 0 1px 1px rgba(90, 171, 16, 5)
}

#animacao .pager span.cycle-pager-active {
    border: 1px solid #5AAB10;
    background: #5AAB10 !important;
    background: linear-gradient(to bottom, rgba(90, 171, 16, 1) 0, rgba(35, 68, 14, 1) 100%) !important;
    box-shadow: 0 0 1px 1px #000 !important
}

.tith {
    margin: auto;
    color: #434343;
    line-height: 28px;
    text-transform: uppercase;
    font-weight: 400
}

.subtith,
.tit1 {
    font-weight: 500;
    text-align: center
}

.tith div {
    display: inline-block;
    padding: 0 48px
}

.tith div:after,
.tith div:before {
    height: 1px;
    width: 30px;
    background: #2B2B2B;
    position: absolute;
    top: 50%;
    content: "";
    display: block;
    line-height: 0
}

.tith div:before {
    left: 0
}

.tith div:after {
    right: 0
}

.subtith {
    font-size: 12px;
    color: #434343;
    line-height: 15px;
    text-transform: uppercase
}

#home-jogos {
    background: #070707;
    padding: 20px 0
}

#home-jogos .subtith {
    margin: 0 0 16px
}

#home-jogos .base {
    position: relative;
    padding: 0 20px;
    margin: 0 0 22px
}

#home-jogos .slides {
    width: 100%;
    overflow: hidden
}

#home-jogos .slides .slide {
    overflow: hidden;
    padding: 0 9px
}

#home-jogos .slides .slide a {
    display: block
}

#home-jogos .slides .slide img {
    display: block;
    margin: auto;
    max-width: 100%
}

#home-jogos .setas {
    position: absolute;
    z-index: 300;
    left: 0;
    top: 50%;
    width: 100%;
    height: 0
}

#home-jogos .setas .seta {
    position: absolute;
    top: -16px;
    cursor: pointer
}

#home-jogos .setas .prev {
    left: 0
}

#home-jogos .setas .next {
    right: 0
}

@media screen and (max-width:600px) {
    #home-jogos {
        margin-top: 90px
    }
}

.tit1,
.titfinal {
    margin: 0 0 1.455em
}

#main {
    padding: 10px 0 100px
}

.tit1 {
    text-transform: uppercase;
    color: #91EC3D;
    line-height: 1.273em;
    position: relative;
    display: block
}

.tit1.dbl-margin {
    margin: 1em 0 2.5em
}

.tit1>div,
.tit2>div {
    display: inline-block;
    margin: auto;
    padding: 0 0 4px;
    width: auto;
    position: relative
}

.tit1>div .linha,
.tit2>div .linha {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    line-height: 0
}

.tit1>div .linha:after,
.tit2>div .linha:after {
    width: 60px;
    height: 1px;
    line-height: inherit;
    background: #91ED3D;
    margin: auto;
    display: block;
    content: ""
}

#home-boxes .box,
#home-boxes .box:after {
    background-size: cover;
    background-position: center center
}

.tit1.esq,
.tit2.esq {
    text-align: left
}

.tit1.esq>div .linha:after,
.tit2.esq>div .linha:after {
    margin: 0
}

.tit2,
.tit3 {
    font-size: 18px;
    line-height: 1.357em;
    text-transform: uppercase;
    color: #91EC3D;
    font-weight: 400;
    margin: 0 0 .7em
}

.tit3 {
    font-size: inherit
}

#home-boxes {
    padding: 20px 0
}

#home-boxes .box {
    border: 1px solid #353535;
    height: 240px;
    display: block;
    padding: 20px;
    position: relative;
    margin: 0 auto 10px;
    max-width: 240px
}

#home-boxes .box:after {
    content: "";
    opacity: .5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1
}

#home-boxes .box.registre:after {
    background-image: url(../img/home_box_registre.jpg)
}

#home-boxes .box.como:after {
    background-image: url(../img/home_box_como.jpg)
}

#home-boxes .box.download:after {
    background-image: url(../img/home_box_download.jpg)
}

#home-boxes .box.planos:after {
    background-image: url(../img/home_box_planos.jpg)
}

#home-boxes .box:hover {
    box-shadow: 0 0 4px rgba(145, 237, 61, .9)
}

#home-boxes .box p {
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 0;
    padding: 20px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, .7)
}

#home-depoimentos .tith {
    margin-bottom: .8em
}

#home-depoimentos .depoimento {
    margin: 0 auto 10px;
    max-width: 240px
}

#home-depoimentos .depoimento .avatar {
    margin: 0 0 18px;
    position: relative
}

#home-depoimentos .depoimento .avatar img {
    margin: auto;
    display: block;
    position: relative;
    z-index: 1;
    max-width: 100%
}

#home-depoimentos .depoimento .avatar:after {
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: url(../img/avatar_bg.png) center center no-repeat;
    background-size: cover
}

#home-depoimentos .depoimento .inner {
    padding: 0 5px
}

#home-depoimentos .depoimento .nome {
    color: #91EC3D;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.1em;
    margin: 0 0 .2em
}

#home-depoimentos .depoimento .mais {
    color: #91EC3D;
    line-height: 1.1em;
    margin: 0 0 1em
}

#home-depoimentos .depoimento .dep {
    font-size: 13px;
    line-height: 1.2em;
    word-wrap: break-word
}

@media (max-width:767px) {
    header {
        position: fixed !important;
        width: 100%
    }
    section {
        position: relative
    }
    #home-boxes .box {
        max-width: 90%;
        height: 120px
    }
    #home-depoimentos .depoimento {
        margin: 30px auto 20px;
        max-width: 240px
    }
    #home-depoimentos .depoimento .avatar {
        margin: 0 0 10px
    }
    #home-depoimentos .depoimento .mais {
        margin: 0 0 .4em
    }
}

#faq .duvida {
    margin-bottom: 2.14em;
    padding: 0 0 0 35px;
    word-wrap: break-word
}

#faq .col:first-child .duvida {
    padding: 0 35px 0 0
}

#jogos-suportados .intro {
    margin: 0 auto 2.53em;
    max-width: 410px;
    text-align: center
}

#jogos-suportados .jogo {
    position: relative;
    margin: 0 auto 38px;
    max-width: 320px
}

#jogos-suportados .jogo .img {
    margin: 0 0 1em
}

#jogos-suportados .jogo .img a {
    display: block
}

#jogos-suportados .jogo .img img {
    display: block;
    margin: auto;
    width: 100%
}

#jogos-suportados .jogo .nome {
    margin: 0 0 .3em
}

#jogos-suportados .jogo p {
    margin: 0 0 1.5em;
    min-height: 6em
}

#download {
    font-size: .93em;
    line-height: 1.308em
}

#download .nao-tem {
    padding: 27px 0 9px;
    margin: 0 0 25px;
    font-size: 18px;
    line-height: 1.2em;
    display: inline-block;
    min-width: 200px;
    border-bottom: solid 1px #515151
}

#download .download-btn img {
    display: block;
    max-width: 100%
}

#download .download-btn a {
    display: block;
    width: 240px;
    height: 80px;
    text-align: left;
    text-indent: -9999px;
    background: url(../img/download_btn.png) no-repeat
}

#download .download-btn a:hover {
    background-position: 0 bottom
}

#download .spacer {
    margin-bottom: 40px
}

#download .req {
    line-height: 1.538em
}

#conta {
    margin-top: 40px
}

#conta .inner {
    padding: 20px
}

#conta .inner .tit2 {
    margin-bottom: 1em
}

#conta .painel {
    border: 1px solid #353535;
    padding: 20px;
    margin: 10px 5px;
    min-height: 240px;
    /* background: url(../img/conta_painel.png) center center no-repeat; */
    background-size: cover;
    width: 100%;
}

#conta #painel-historico table th,
#conta #painel-vencimento table th {
    font-weight: 400;
    padding: 0 10px 5px 0;
    text-align: left;
    text-transform: uppercase
}

#conta #painel-historico table td,
#conta #painel-vencimento table td {
    padding: .7em 10px .7em 0
}

#conta #painel-historico table td:last-child,
#conta #painel-vencimento table td:last-child {
    padding-right: 0
}

#conta #painel-historico,
#conta #painel-vencimento {
    background-position: center top;
    background-repeat: no-repeat;
    padding-bottom: 5px;
    background-size: auto;
    min-height: 0
}

#conta .painel .tit2 {
    margin-bottom: 1.222em
}

#conta #painel-dados table {
    margin: 0 0 20px;
    width: 100%;
    table-layout: fixed
}

#conta #painel-dados table tr td {
    padding-bottom: 5px;
    word-wrap: break-word;
    width: 28%
}

#conta #painel-dados table tr td:first-child {
    padding-right: 15px;
    width: 10%;
    white-space: nowrap
}

#conta #painel-cupom p {
    margin-bottom: .5em
}

#conta #painel-cupom .form-control {
    margin-bottom: 5px
}

#conta #painel-historico {
    margin: 10px 0
}

#conta #painel-historico table,
#conta #painel-vencimento table {
    width: 100%
}

#conta #painel-historico table th {
    color: #54ff00
}

#conta #painel-historico table tr:last-child td {
    border-bottom: none
}

#conta #painel-historico table tr:hover td {
    color: #d1d1d1
}

#conta #painel-vencimento {
    margin: 20px 0
}

#conta #painel-historico li,
#conta #painel-vencimento li {
    display: inline-block;
    width: 28px;
    height: 28px;
    text-align: center;
    font-size: 14px;
    line-height: 26px;
    background: #1c1c1c;
    border-radius: 5px;
    border-color: #444;
    border-width: 1px;
    border-style: solid;
    margin: 20px 2px
}

#conta .disabled {
    display: none
}

#conta .active {
    border-color: #92ed3e;
    color: #92ed3e
}

#conta #painel-vencimento table th {
    color: #54ff00
}

#conta #painel-vencimento table tr:last-child td {
    border-bottom: none
}

#conta #painel-vencimento table tr:hover td {
    color: #d1d1d1
}

#conta .btns {
    text-align: right
}

#conta .btns .btn {
    margin: 0 0 10px 10px;
    min-width: 40%
}

#conta #painel-status table {
    margin: 0;
    width: 100%
}

#conta #painel-status table tr td {
    padding-bottom: 10px;
    line-height: 1.34em
}

#conta #painel-status table tr td:last-child {
    text-align: right
}

#conta #painel-status table+.btn {
    margin-top: 22px
}

#conta #painel-comprar .qtd {
    min-height: 40px
}

#conta #painel-comprar .qtd p {
    margin: 0 !important;
    float: left
}

#conta #painel-comprar .qtd .form-select {
    float: right;
    width: 90px
}

#conta #painel-comprar .qtd .form-select:after {
    display: block;
    height: 0;
    line-height: 0;
    content: "";
    clear: both
}

#conta #painel-infos {
    background-image: none;
    min-height: 0
}

@media (max-width:768px) {
    #btarrow,
    #fastmenu {
        position: fixed;
        top: 0;
        bottom: 0;
        background: #111;
        transition: left .1s ease-in-out
    }
    #fastmenu {
        z-index: 1002;
        padding: 0;
        margin: 0;
        left: 0;
        overflow-y: scroll;
        overflow-x: hidden;
        border: 1px solid #444;
        border-width: 0 1px 0 0;
        border-right-color: #444
    }
    #swipe {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0
    }
    .hideit {
        left: -100% !important
    }
    .btshow {
        left: 0 !important
    }
    #btarrow {
        left: 290px;
        font-size: 48px;
        margin: auto;
        height: 100px;
        overflow: visible;
        line-height: 90px;
        width: 34px;
        border-radius: 0 10px 10px 0;
        border: 1px solid #444;
        border-width: 1px 1px 1px 0
    }
}

.modal,
.modal .overlay {
    position: fixed;
    top: 0;
    height: 100%;
    left: 0
}

@media (min-width:769px) {
    #btarrow,
    #swipe {
        display: none
    }
}

.modal .base,
.modal_payments .base {
    display: table;
    vertical-align: middle
}

.modal {
    z-index: 3000;
    width: 100%;
    height: 100vh;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    overflow: auto
}

.modal.open {
    visibility: visible;
    opacity: 1
}

.hidden,
.invisible,
.modal .janela .metodo label input,
.modal_payments,
.modal_payments .janela .metodo label input {
    visibility: hidden
}

.modal .overlay {
    width: 100%;
    height: 100vh;
    z-index: 3001;
    background: rgba(0, 0, 0, .9)
}

.modal_payments,
.modal_payments .overlay {
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%
}

.modal .base {
    width: 100%;
    height: 100%
}

.modal .base .base {
    display: table-cell
}

.modal .janela {
    display: block;
    margin: auto;
    text-align: left;
    background: #151515;
    border-radius: 8px;
    box-shadow: 0 0 8px 3px rgba(115, 115, 115, .7);
    width: 95%;
    max-width: 490px;
    padding: 30px 30px 5px;
    position: relative;
    z-index: 3002
}

.modal .janela .fechar {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px
}

.modal .janela .metodo {
    text-align: center;
    margin: 0 0 10px
}

.modal .janela .metodo label {
    display: inline-block;
    margin: 0 5px 10px;
    cursor: pointer
}

.modal .janela .metodo label:first-child {
    margin-left: 0
}

.modal .janela .metodo label:last-child {
    margin-right: 0
}

.modal .janela .metodo label img {
    display: block;
    border-radius: 6px;
    width: 80px
}

.modal .janela .metodo label input:checked+img {
    box-shadow: 0 0 5px 5px rgba(145, 237, 61, .8)
}

.modal .janela hr {
    border-color: #000
}

.modal_payments {
    z-index: 3000;
    left: 0;
    height: 100vh;
    text-align: center;
    opacity: 0;
    overflow: auto
}

.modal_payments.open {
    visibility: visible;
    opacity: 1
}

.modal_payments .overlay {
    left: 0;
    height: 100vh;
    z-index: 3001;
    background: rgba(0, 0, 0, .9)
}

.modal_payments .base {
    width: 100%;
    height: 100%
}

.modal_payments .base .base {
    display: table-cell
}

.modal_payments .janela {
    display: block;
    margin: auto;
    text-align: left;
    background: #151515;
    border-radius: 8px;
    box-shadow: 0 0 8px 3px rgba(115, 115, 115, .7);
    width: 95%;
    max-width: 490px;
    padding: 30px;
    position: relative;
    z-index: 3002
}

.modal_payments .janela .fechar {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px
}

.modal_payments .janela .metodo {
    text-align: center;
    margin: 0 0 10px
}

.modal_payments .janela .metodo label {
    display: inline-block;
    margin: 0 5px 10px;
    cursor: pointer
}

.modal_payments .janela .metodo label:first-child {
    margin-left: 0
}

.modal_payments .janela .metodo label:last-child {
    margin-right: 0
}

.modal_payments .janela .metodo label img {
    display: block;
    border-radius: 6px;
    width: 80px
}

.modal_payments .janela .metodo label input:checked+img {
    box-shadow: 0 0 5px 5px rgba(145, 237, 61, .8)
}

.modal_payments .janela hr {
    border-color: #000
}

#modal-comprar .janela .qtd {
    min-height: 40px
}

#modal-comprar .janela .qtd p {
    margin: 0 10px 0 0 !important;
    float: right
}

#modal-comprar .janela .qtd .form-select {
    float: right;
    width: 90px
}

#modal-comprar .janela .qtd .form-select:after {
    display: block;
    height: 0;
    line-height: 0;
    content: "";
    clear: both
}

#modal-comprar .janela .btn {
    width: 100%;
    max-width: 210px
}

#modal-relatorio .stats {
    margin: 0 0 20px
}

#modal-relatorio table {
    width: 100%
}

#modal-relatorio table td {
    line-height: 1.2em;
    padding-bottom: 5px
}

#modal-relatorio table td:last-child {
    text-align: right
}

.modal-plano .janela .cupom .campocupom {
    max-width: 270px;
    display: none
}

.modal-plano .janela .btn {
    width: 100%;
    max-width: 210px
}

#planos .plano {
    background: url(../img/bg-up.png) center top no-repeat #070707;
    margin: 60px 0 40px;
    position: relative;
    padding: 80px 0 0
}

#planos .plano .img {
    position: absolute;
    left: 0;
    top: -60px;
    width: 100%;
    z-index: 1
}

#planos .plano .img img {
    display: block;
    margin: auto;
    max-width: 100%
}

#planos .plano .faixa {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2
}

#planos .plano .info {
    padding: 0 20px 22px;
    border-bottom: solid 1px #292929;
    text-align: center
}

#planos .plano .info .tit2 {
    margin-bottom: .2em
}

#planos .plano .info .valor {
    font-size: 28px;
    font-weight: 700;
    line-height: 30px;
    vertical-align: top;
    color: #91EC3D;
    margin-bottom: .2em
}

#planos .plano .info .valor small {
    font-size: 14px;
    vertical-align: top;
    line-height: 30px;
    font-weight: 400
}

#footer .social li a,
#planos .plano .btn,
.alert .alert-link,
.close {
    font-weight: 700
}

#planos .plano .info p {
    font-size: 13px
}

#planos .plano .btn {
    border-radius: 0;
    padding: 0;
    line-height: 40px;
    color: #91EC3D;
    border: none;
    background: url(../img/planos_btn.png) center bottom no-repeat;
    background-size: 100% 80px
}

#planos .plano .btn:hover {
    background-position: center top
}

#footer {
    /*background:#070707;*/
    background-position: bottom;
    background-repeat: no-repeat;
    font-size: 12px;
    color: #434343;
    line-height: 1.2em;
    text-transform: uppercase;
    position: relative
}

#footer .container {
    /*@durvalpcnbackground:url(../img/footer_bg.png) right bottom no-repeat;padding-top:40px;padding-bottom:40px*/
}

#footer .copy,
#footer .social {
    padding: 8px 0;
    display: inline-block;
    vertical-align: middle
}

#footer a:hover {
    color: #BABABA
}

#footer .copy {
    line-height: 1.667em;
    margin: 0
}

.footer-maininfo {
    width: 29.8%
}

.footer-socialinfo {
    width: 45.2%
}

#footer .social li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 22px 0 0
}

#footer .social li:last-child {
    margin-right: 0 !important
}

#footer .social li a {
    display: block;
    position: relative;
    padding: 5px 0 0 40px;
    min-height: 40px;
    line-height: 1.3em
}

#footer .social li a .fa {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 40px;
    line-height: 40px
}

#flogo {
    text-align: center
}

#flogo a {
    display: inline-block;
    max-width: 100%
}

#flogo a img {
    display: block;
    max-width: 100%
}

#loading {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5000;
    width: 100vw;
    height: 100vh;
    background: url(../img/loading.gif) center center no-repeat rgba(0, 0, 0, .8)
}

@media (min-width:1030px) {
    .qlg {
        margin-bottom: 20px
    }
}

@media (max-width:1029px) {
    .qmd {
        margin-bottom: 20px
    }
    #menu .menu {
        width: 400px
    }
    #animacao .pager {
        padding-left: 410px
    }
}

@media (max-width:991px) {
    .qsm {
        margin-bottom: 20px
    }
    #menu .menu {
        width: 350px;
        font-size: 12px
    }
    #header .botoes .btn {
        padding-left: 10px !important;
        padding-right: 10px !important;
        font-size: 12px !important
    }
    #main {
        padding: 50px 0 80px
    }
    #animacao .pager {
        padding-left: 340px
    }
    #faq .duvida {
        padding: 0 !important
    }
    #footer {
        padding: 32px 0
    }
}

@media (max-width:767px) {
    #home-jogos .slides .slide {
        width: calc(100vw - 50px)
    }
    #wrapper {
        padding: 0
    }
    .qxs {
        margin-bottom: 20px
    }
    .form-checks label {
        white-space: normal
    }
    #menu {
        float: right;
        width: 150px
    }
    #menu .menu {
        font-size: 14px;
        position: absolute;
        right: 0;
        top: 75px;
        border: 1px solid #444;
        border-radius: 5px;
        background: #1c1c1c;
        padding: 5px 0;
        z-index: 1050;
        text-align: right;
        width: auto;
        display: none
    }
    .footer-maininfo,
    .footer-socialinfo {
        text-align: center;
        width: 100%
    }
    #menu.open .menu {
        display: block
    }
    #menu .menu>li {
        display: block;
        flex: none
    }
    #menu .menu>li>a {
        display: block;
        line-height: 40px;
        border: none;
        padding: 0 15px
    }
    #menu .menu>li>ul {
        position: relative;
        left: auto;
        top: auto;
        border: none;
        padding: 0;
        display: none !important;
        background: 0 0
    }
    #menu .menu>li.open>ul {
        display: block !important
    }
    .submenu {
        display: none !important
    }
    #animacao .pager {
        padding: 10px 0
    }
    #main {
        padding: 40px 10px 60px
    }
    .addmargin {
        margin-top: 0px
    }
    #download .nao-tem {
        padding-top: 0
    }
    #footer {
        padding: 0;
        height: 100%;
        position: relative
    }
    .footer-maininfo {
        margin: auto;
        height: auto
    }
    #footer .social {
        display: block;
        vertical-align: middle;
        padding: 8px 0;
        left: 0;
        margin: auto;
        right: 0
    }
    .footer-logo {
        width: 100%;
        margin: auto
    }
    #flogo {
        text-align: left;
        padding: 20px 0
    }
    #flogo a {
        display: block;
        max-width: 100%
    }
    #flogo a img {
        display: block;
        max-width: 100%;
        margin: auto
    }
    #footer .container {
        padding-top: 20px !important;
        padding-bottom: 20px/*@durvalpcn*/
    }
    #conta {
        margin-top: 0
    }
}

@media (max-width:450px) {
    #logo a img,
    #menu {
        width: 120px
    }
    #logo {
        padding-top: 15px
    }
    #menu {
        padding: 0
    }
    #animacao .slides .slide img.mobile {
        display: block
    }
    #animacao .slides .slide img.desktop {
        display: none
    }
    #conta .painel {
        padding: 10px
    }
    #conta #painel-historico table {
        font-size: 10px
    }
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px
}

.alert h4 {
    margin-top: 0;
    color: inherit
}

.alert>p,
.alert>ul {
    margin-bottom: 0
}

.alert>p+p {
    margin-top: 5px
}

.alert-dismissable,
.alert-dismissible {
    padding-right: 35px
}

.alert-dismissable .close,
.alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit
}

.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d
}

.alert-success hr {
    border-top-color: #c9e2b3
}

.alert-success .alert-link {
    color: #2b542c
}

.alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f
}

.alert-info hr {
    border-top-color: #a6e1ec
}

.alert-info .alert-link {
    color: #245269
}

.alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b
}

.alert-warning hr {
    border-top-color: #f7e1b5
}

.alert-warning .alert-link {
    color: #66512c
}

.alert-danger {
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-color: #54ff00 !important;
    color: #a94442 !important
}

.alert-danger hr {
    border-top-color: #e4b9c0
}

.alert-danger .alert-link {
    color: #843534
}

div.g-recaptcha {
    margin: 0 auto;
    width: 304px
}

.game-name,
.lng-opt i {
    margin-top: 5px
}

.game-item {
    margin-bottom: 20px;
    padding-right: 5px;
    padding-left: 5px;
    text-align: -webkit-center
}

.game-name {
    max-height: 15px;
    font-size: 13px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

a:hover .game-name {
    color: #91EC3E
}

.close {
    float: right;
    font-size: 21px;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2
}

.close:focus,
.close:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: .5
}

button.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0
}

/*! HTML5 Boilerplate v4.3.0 - FINAL */