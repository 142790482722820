
.cid-rrLmmgZfdP .navbar {
    padding: 0;
    background: #141414;
    transition: none;
    min-height: 77px;
}

.cid-rrLmmgZfdP .navbar-dropdown.bg-color.transparent.opened {
    background: #141414;
}

.cid-rrLmmgZfdP a {
    font-style: normal;
}

.cid-rrLmmgZfdP .nav-item span {
    padding-right: 0.4em;
    line-height: 0.5em;
    vertical-align: text-bottom;
    position: relative;
    text-decoration: none;
}

.cid-rrLmmgZfdP .nav-item a {
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem 0 !important;
    margin: 0rem .65rem !important;
    -webkit-align-items: center;
    -webkit-justify-content: center;
}

.cid-rrLmmgZfdP .nav-item:focus,
.cid-rrLmmgZfdP .nav-link:focus {
    outline: none;
}

.cid-rrLmmgZfdP .btn {
    padding: 0.4rem 1.5rem;
    display: -webkit-inline-flex;
    align-items: center;
    -webkit-align-items: center;
}

.cid-rrLmmgZfdP .btn .mbr-iconfont {
    font-size: 1.6rem;
}

.cid-rrLmmgZfdP .menu-logo {
    margin-right: auto;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand {
    display: flex;
    margin-left: 5rem;
    padding: 0;
    transition: padding .2s;
    min-height: 3.8rem;
    -webkit-align-items: center;
    align-items: center;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-caption-wrap {
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    word-break: break-word;
    min-width: 7rem;
    margin: .3rem 0;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption {
    line-height: 1.2rem !important;
    padding-right: 2rem;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo {
    /*@durvalpcnfont-size: 4rem;*/
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo img {
    display: flex;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo .mbr-iconfont {
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo a {
    display: inline-flex;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse {
    justify-content: center;
    -webkit-justify-content: center;
    padding-right: 5rem;
    width: auto;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-nav {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    padding-left: 0;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item {
    -webkit-align-self: center;
    align-self: center;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-buttons {
    padding-left: 0;
    padding-bottom: 0;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu {
    background: #141414;
    display: none;
    position: absolute;
    min-width: 5rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    text-align: left;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-item {
    width: auto;
    padding: 0.235em 1.5385em 0.235em 1.5385em !important;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-item::after {
    right: 0.5rem;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-submenu {
    margin: 0;
}

.cid-rrLmmgZfdP .dropdown.open>.dropdown-menu {
    display: block;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm.opened:after {
    position: absolute;
    width: 100vw;
    height: 100vh;
    content: '';
    background-color: rgba(0, 0, 0, 0.1);
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    z-index: 1000;
}

.cid-rrLmmgZfdP .navbar.navbar-short {
    min-height: 60px;
    transition: all .2s;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-toggler-right {
    top: 20px;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a {
    font-size: 2.5rem !important;
    line-height: 2.5rem;
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a .mbr-iconfont {
    font-size: 2.5rem !important;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a img {
    height: 3rem !important;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-brand {
    min-height: 3rem;
}

.cid-rrLmmgZfdP button.navbar-toggler {
    width: 31px;
    height: 18px;
    cursor: pointer;
    transition: all .2s;
    top: 1.5rem;
    right: 1rem;
}

.cid-rrLmmgZfdP button.navbar-toggler:focus {
    outline: none;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span {
    position: absolute;
    right: 0;
    width: 30px;
    height: 2px;
    border-right: 5px;
    background-color: #ffffff;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(1) {
    top: 0;
    transition: all .2s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(2) {
    top: 8px;
    transition: all .15s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(3) {
    top: 8px;
    transition: all .15s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(4) {
    top: 16px;
    transition: all .2s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(1) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all .25s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: all .25s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(4) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
}

.cid-rrLmmgZfdP .collapsed.navbar-expand {
    flex-direction: column;
    -webkit-flex-direction: column;
}

.cid-rrLmmgZfdP .collapsed .btn {
    display: -webkit-flex;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse {
    display: none !important;
    padding-right: 0 !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show {
    display: block !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-nav,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-nav {
    display: block;
    text-align: center;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-nav .nav-item {
    clear: both;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-buttons,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-buttons {
    text-align: center;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-buttons:last-child {
    margin-bottom: 1rem;
}

@media (min-width: 992px) {
    .cid-rrLmmgZfdP .collapsed:not(.navbar-short) .navbar-collapse {
        max-height: calc(98.5vh - 3.8rem);
    }
}

.cid-rrLmmgZfdP .collapsed button.navbar-toggler {
    display: block;
}

.cid-rrLmmgZfdP .collapsed .navbar-brand {
    margin-left: 1rem !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-toggleable-sm {
    flex-direction: column;
    -webkit-flex-direction: column;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-menu {
    width: 100%;
    text-align: center;
    position: relative;
    opacity: 0;
    overflow: hidden;
    display: block;
    height: 0;
    visibility: hidden;
    padding: 0;
    transition-duration: .5s;
    transition-property: opacity, padding, height;
}

.cid-rrLmmgZfdP .collapsed .dropdown.open>.dropdown-menu {
    position: relative;
    opacity: 1;
    height: auto;
    padding: 1.4rem 0;
    visibility: visible;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-submenu {
    left: 0;
    text-align: center;
    width: 100%;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
    margin-top: 0;
    position: inherit;
    right: 0;
    top: 50%;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .3em;
    vertical-align: middle;
    content: "";
    border-top: .30em solid;
    border-right: .30em solid transparent;
    border-left: .30em solid transparent;
}

@media (max-width: 991px) {
    .cid-rrLmmgZfdP .navbar-expand {
        flex-direction: column;
        -webkit-flex-direction: column;
    }
    .cid-rrLmmgZfdP img {
        height: 3.8rem !important;
    }
    .cid-rrLmmgZfdP .btn {
        display: -webkit-flex;
    }
    .cid-rrLmmgZfdP button.navbar-toggler {
        display: block;
    }
    .cid-rrLmmgZfdP .navbar-brand {
        margin-left: 1rem !important;
    }
    .cid-rrLmmgZfdP .navbar-toggleable-sm {
        flex-direction: column;
        -webkit-flex-direction: column;
    }
    .cid-rrLmmgZfdP .navbar-collapse {
        display: none !important;
        padding-right: 0 !important;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing,
    .cid-rrLmmgZfdP .navbar-collapse.show {
        display: block !important;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-nav,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-nav {
        display: block;
        text-align: center;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-nav .nav-item,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-nav .nav-item {
        clear: both;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-buttons,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-buttons {
        text-align: center;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-buttons:last-child,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-buttons:last-child {
        margin-bottom: 1rem;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-menu {
        width: 100%;
        text-align: center;
        position: relative;
        opacity: 0;
        overflow: hidden;
        display: block;
        height: 0;
        visibility: hidden;
        padding: 0;
        transition-duration: .5s;
        transition-property: opacity, padding, height;
    }
    .cid-rrLmmgZfdP .dropdown.open>.dropdown-menu {
        position: relative;
        opacity: 1;
        height: auto;
        padding: 1.4rem 0;
        visibility: visible;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-submenu {
        left: 0;
        text-align: center;
        width: 100%;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
        margin-top: 0;
        position: inherit;
        right: 0;
        top: 50%;
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: .3em;
        vertical-align: middle;
        content: "";
        border-top: .30em solid;
        border-right: .30em solid transparent;
        border-left: .30em solid transparent;
    }
}

@media (min-width: 767px) {
    .cid-rrLmmgZfdP .menu-logo {
        flex-shrink: 0;
        -webkit-flex-shrink: 0;
    }
}

.cid-rrLmmgZfdP .navbar-collapse {
    flex-basis: auto;
    -webkit-flex-basis: auto;
}

.cid-rrLmmgZfdP .nav-link:hover,
.cid-rrLmmgZfdP .dropdown-item:hover {
    color: #c1c1c1 !important;
}

.cid-rrLmo3HcjB {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrLmo3HcjB .mbr-section-subtitle {
    color: white;
}

.cid-rrLmo3HcjB H2 {
    color: #9eff00;
}

.cid-rrLoh2mPwH {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #141414;
}

.cid-rrLoh2mPwH .mbr-slider .carousel-control {
    background: #1b1b1b;
}

.cid-rrLoh2mPwH .mbr-slider .carousel-control-prev {
    left: 0;
    margin-left: 2.5rem;
}

.cid-rrLoh2mPwH .mbr-slider .carousel-control-next {
    right: 0;
    margin-right: 2.5rem;
}

.cid-rrLoh2mPwH .mbr-slider .modal-body .close {
    background: #1b1b1b;
}

.cid-rrLoh2mPwH .row>.row {
    display: block;
}

.cid-rrLoh2mPwH .mbr-gallery-item {
    width: 100%;
}

.cid-rrLoh2mPwH .mbr-gallery-item>div::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #554346;
    opacity: 0;
    -webkit-transition: 0.2s opacity ease-in-out;
    transition: 0.2s opacity ease-in-out;
}

.cid-rrLoh2mPwH .mbr-gallery-item>div {
    position: relative;
}

.cid-rrLoh2mPwH .mbr-gallery-item>div:hover .mbr-gallery-title::before {
    background: transparent !important;
}

.cid-rrLoh2mPwH .mbr-gallery-item>div:hover:before {
    opacity: 0 !important;
}

.cid-rrLoh2mPwH .mbr-gallery-title {
    font-size: .9em;
    position: absolute;
    display: block;
    width: 100%;
    bottom: 0;
    padding: 1rem;
    color: #fff;
    z-index: 2;
}

.cid-rrLoh2mPwH .mbr-gallery-title:before {
    content: " ";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    position: absolute;
    background: #554346 !important;
    opacity: 0;
    -webkit-transition: 0.2s background ease-in-out;
    transition: 0.2s background ease-in-out;
}

.cid-rrLq8rakdr {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrLqJ2eqKI {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrLqJ2eqKI .mbr-section-subtitle {
    color: white;
}

.cid-rrLqJ2eqKI H2 {
    color: #9eff00;
}

.cid-rrLqTpBeh6 {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrLqTpBeh6 h4 {
    font-weight: 500;
    margin-bottom: 0;
    text-align: left;
}

.cid-rrLqTpBeh6 p {
    color: white;
    text-align: left;
}

.cid-rrLqTpBeh6 .card-box {
    padding-top: 2rem;
}

.cid-rrLqTpBeh6 .card-wrapper {
    height: 100%;
}

.cid-rrLr6iDrk8 {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #141414;
}

.cid-rrLr6iDrk8 .mbr-section-subtitle {
    color: white;
}

.cid-rrLr6iDrk8 H2 {
    color: #9eff00;
}

.cid-rrLsoZBK88 {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrLswBF1lc {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrLswBF1lc .mbr-section-subtitle {
    color: white;
}

.cid-rrLswBF1lc H2 {
    color: #9eff00;
}

.cid-rrLtqlSrJc {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #141414;
}

.cid-rrLtqlSrJc .card-box {
    padding: 0 2rem;
}

.cid-rrLtqlSrJc .mbr-section-btn {
    padding-top: 1rem;
}

.cid-rrLtqlSrJc .mbr-section-btn a {
    margin-top: 1rem;
    margin-bottom: 0;
}

.cid-rrLtqlSrJc h4 {
    font-weight: 500;
    margin-bottom: 0;
    text-align: left;
    padding-top: 2rem;
}

.cid-rrLtqlSrJc p {
    margin-bottom: 0;
    text-align: left;
    padding-top: 1.5rem;
}

.cid-rrLtqlSrJc .mbr-text {
    color: white;
    text-align: center;
}

.cid-rrLtqlSrJc .card-wrapper {
    height: 100% padding:0px;
    ;
    padding-bottom: 2rem;
    background: #000000;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s;
    border-radius: 50px;
    border: solid #9cfe1e 2px;
}

.cid-rrLtqlSrJc .card-wrapper:hover {
    /*box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s;*/
}

.cid-rrLtqlSrJc .card-title {
    color: #9eff00;
    text-align: center;
}

.cid-rrLui4XW80 {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrLui4XW80 .mbr-section-subtitle {
    color: white;
}

.cid-rrLui4XW80 H2 {
    color: #9eff00;
}

.cid-rrLulrKYE0 {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrLulrKYE0 .mbr-section-subtitle {
    color: white;
}

.cid-rrLulrKYE0 H2 {
    color: #9eff00;
}

.cid-rrLuBvXhQV {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #141414;
}

.cid-rrLuBvXhQV h4 {
    font-weight: 500;
    margin-bottom: 0;
    text-align: left;
}

.cid-rrLuBvXhQV p {
    color: white;
    text-align: left;
}

.cid-rrLuBvXhQV .card-box {
    padding-top: 2rem;
}

.cid-rrLuBvXhQV .card-wrapper {
    height: 100%;
}

.cid-rrLuBvXhQV .card-title {
    color: #9eff00;
}

.cid-rrLwUyC74z {
    padding-top: 30px;
    padding-bottom: 0px;
    background-color: #000000;
}

.cid-rrLwUyC74z .card-box {
    background-color: #000000;
    padding: 2rem;
}

.cid-rrLwUyC74z h4 {
    font-weight: 500;
    margin-bottom: 0;
    text-align: left;
}

.cid-rrLwUyC74z p {
    color: #767676;
    text-align: left;
}

.cid-rrLwUyC74z .card-wrapper {
    position: relative;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s;
}

.cid-rrLwUyC74z .card-wrapper:hover {
    /*box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s;*/
}

.cid-rrLwUyC74z .card-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.cid-rrLxDiHwOn {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #000000;
}

.cid-rrLxDiHwOn .media-container-row .mbr-text {
    color: #707070;
}

/* @durvalpcn */

.cid-rrLNJ9XQuA {
    padding-top: 20px;
    padding-bottom: 0px;
    background: #0b0b0b !important;
    height: 120px;
}

.cid-rrLNJ9XQuA .card-box {
    background-color: #0b0b0b;
    padding: 2rem;
    padding-right: 2rem !important;
}

.cid-rrLNJ9XQuA h4 {
    font-weight: 500;
    margin-bottom: 0;
    text-align: left;
}

.cid-rrLNJ9XQuA p {
    color: white;
    text-align: left;
}

.cid-rrLNJ9XQuA .card-wrapper {
    position: relative;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s;
}

.cid-rrLNJ9XQuA .card-wrapper:hover {
    /*box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s;*/
}

.cid-rrLNJ9XQuA .card-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.cid-rrLNJ9XQuA P {
    text-align: left;
}

.cid-rrLN3Oh03m {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #141414;
}

.cid-rrLN3Oh03m .card-box {
    background-color: #141414;
    padding: 1rem;
}

.cid-rrLN3Oh03m h4 {
    font-weight: 500;
    margin-bottom: 0;
    text-align: left;
}

.cid-rrLN3Oh03m p {
    color: white;
    text-align: left;
}

.cid-rrLN3Oh03m .card-wrapper {
    position: relative;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s;
}

.cid-rrLN3Oh03m .card-wrapper:hover {
    /*box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s;*/
}

.cid-rrLN3Oh03m .card-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.cid-rrLN3Oh03m P {
    text-align: center;
}

.cid-rrLMOuuC1o {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #141414;
}

.cid-rrLMOuuC1o .card-box {
    padding: 0 2rem;
}

.cid-rrLMOuuC1o .mbr-section-btn {
    padding-top: 1rem;
}

.cid-rrLMOuuC1o .mbr-section-btn a {
    margin-top: 1rem;
    margin-bottom: 0;
}

.cid-rrLMOuuC1o h4 {
    font-weight: 500;
    margin-bottom: 0;
    text-align: left;
    padding-top: 2rem;
}

.cid-rrLMOuuC1o p {
    margin-bottom: 0;
    text-align: left;
    padding-top: 1.5rem;
}

.cid-rrLMOuuC1o .mbr-text {
    color: white;
    text-align: center;
}

.cid-rrLMOuuC1o .card-wrapper {
    height: 100%;
    padding-bottom: 2rem;
    background: #000000;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s;
}

.cid-rrLMOuuC1o .card-wrapper:hover {
    /*box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s;*/
}

.cid-rrLMOuuC1o .card-title {
    color: #9eff00;
    text-align: center;
}

.cid-rrLNm8zS0E {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #141414;
}

.cid-rrLNm8zS0E .card-box {
    background-color: #141414;
    padding: 2rem;
}

.cid-rrLNm8zS0E h4 {
    font-weight: 500;
    margin-bottom: 0;
    text-align: left;
}

.cid-rrLNm8zS0E p {
    color: white;
    text-align: left;
}

.cid-rrLNm8zS0E .card-wrapper {
    position: relative;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s;
}

.cid-rrLNm8zS0E .card-wrapper:hover {
    /*box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s;*/
}

.cid-rrLNm8zS0E .card-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.cid-rrLNm8zS0E P {
    text-align: left;
}

.cid-rrLMRatNRJ {
    padding-top: 0px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrLMRatNRJ .mbr-section-subtitle {
    color: white;
}

.cid-rrLMRatNRJ H2 {
    color: #9eff00;
}

.cid-rrLxDiHwOn {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #000000;
}

.cid-rrLxDiHwOn .media-container-row .mbr-text {
    color: #707070;
}

.cid-rrLSpuslof {
    padding-top: 120px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrLSpuslof .mbr-section-subtitle {
    color: white;
}

.cid-rrLSpuslof H2 {
    color: #9eff00;
}

.cid-rrLmmgZfdP .navbar {
    padding: .5rem 0;
    background: #141414;
    transition: none;
    min-height: 77px;
}

.cid-rrLmmgZfdP .navbar-dropdown.bg-color.transparent.opened {
    background: #141414;
}

.cid-rrLmmgZfdP a {
    font-style: normal;
}

.cid-rrLmmgZfdP .nav-item span {
    padding-right: 0.4em;
    line-height: 0.5em;
    vertical-align: text-bottom;
    position: relative;
    text-decoration: none;
}

.cid-rrLmmgZfdP .nav-item a {
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem 0 !important;
    margin: 0rem .65rem !important;
    -webkit-align-items: center;
    -webkit-justify-content: center;
}

.cid-rrLmmgZfdP .nav-item:focus,
.cid-rrLmmgZfdP .nav-link:focus {
    outline: none;
}

.cid-rrLmmgZfdP .btn {
    padding: 0.4rem 1.5rem;
    display: -webkit-inline-flex;
    align-items: center;
    -webkit-align-items: center;
}

.cid-rrLmmgZfdP .btn .mbr-iconfont {
    font-size: 1.6rem;
}

.cid-rrLmmgZfdP .menu-logo {
    margin-right: auto;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand {
    display: flex;
    margin-left: 5rem;
    padding: 0;
    transition: padding .2s;
    min-height: 3.8rem;
    -webkit-align-items: center;
    align-items: center;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-caption-wrap {
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    word-break: break-word;
    min-width: 7rem;
    margin: .3rem 0;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption {
    line-height: 1.2rem !important;
    padding-right: 2rem;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo {
    /*@durvalpcnfont-size: 4rem;*/
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo img {
    display: flex;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo .mbr-iconfont {
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo a {
    display: inline-flex;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse {
    justify-content: center;
    -webkit-justify-content: center;
    padding-right: 5rem;
    width: auto;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-nav {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    padding-left: 0;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item {
    -webkit-align-self: center;
    align-self: center;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-buttons {
    padding-left: 0;
    padding-bottom: 0;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu {
    background: #141414;
    display: none;
    position: absolute;
    min-width: 5rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    text-align: left;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-item {
    width: auto;
    padding: 0.235em 1.5385em 0.235em 1.5385em !important;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-item::after {
    right: 0.5rem;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-submenu {
    margin: 0;
}

.cid-rrLmmgZfdP .dropdown.open>.dropdown-menu {
    display: block;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm.opened:after {
    position: absolute;
    width: 100vw;
    height: 100vh;
    content: '';
    background-color: rgba(0, 0, 0, 0.1);
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    z-index: 1000;
}

.cid-rrLmmgZfdP .navbar.navbar-short {
    min-height: 60px;
    transition: all .2s;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-toggler-right {
    top: 20px;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a {
    font-size: 2.5rem !important;
    line-height: 2.5rem;
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a .mbr-iconfont {
    font-size: 2.5rem !important;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a img {
    height: 3rem !important;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-brand {
    min-height: 3rem;
}

.cid-rrLmmgZfdP button.navbar-toggler {
    width: 31px;
    height: 18px;
    cursor: pointer;
    transition: all .2s;
    top: 1.5rem;
    right: 1rem;
}

.cid-rrLmmgZfdP button.navbar-toggler:focus {
    outline: none;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span {
    position: absolute;
    right: 0;
    width: 30px;
    height: 2px;
    border-right: 5px;
    background-color: #ffffff;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(1) {
    top: 0;
    transition: all .2s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(2) {
    top: 8px;
    transition: all .15s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(3) {
    top: 8px;
    transition: all .15s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(4) {
    top: 16px;
    transition: all .2s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(1) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all .25s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: all .25s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(4) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
}

.cid-rrLmmgZfdP .collapsed.navbar-expand {
    flex-direction: column;
    -webkit-flex-direction: column;
}

.cid-rrLmmgZfdP .collapsed .btn {
    display: -webkit-flex;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse {
    display: none !important;
    padding-right: 0 !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show {
    display: block !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-nav,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-nav {
    display: block;
    text-align: center;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-nav .nav-item {
    clear: both;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-buttons,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-buttons {
    text-align: center;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-buttons:last-child {
    margin-bottom: 1rem;
}

@media (min-width: 992px) {
    .cid-rrLmmgZfdP .collapsed:not(.navbar-short) .navbar-collapse {
        max-height: calc(98.5vh - 3.8rem);
    }
}

.cid-rrLmmgZfdP .collapsed button.navbar-toggler {
    display: block;
}

.cid-rrLmmgZfdP .collapsed .navbar-brand {
    margin-left: 1rem !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-toggleable-sm {
    flex-direction: column;
    -webkit-flex-direction: column;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-menu {
    width: 100%;
    text-align: center;
    position: relative;
    opacity: 0;
    overflow: hidden;
    display: block;
    height: 0;
    visibility: hidden;
    padding: 0;
    transition-duration: .5s;
    transition-property: opacity, padding, height;
}

.cid-rrLmmgZfdP .collapsed .dropdown.open>.dropdown-menu {
    position: relative;
    opacity: 1;
    height: auto;
    padding: 1.4rem 0;
    visibility: visible;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-submenu {
    left: 0;
    text-align: center;
    width: 100%;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
    margin-top: 0;
    position: inherit;
    right: 0;
    top: 50%;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .3em;
    vertical-align: middle;
    content: "";
    border-top: .30em solid;
    border-right: .30em solid transparent;
    border-left: .30em solid transparent;
}

@media (max-width: 991px) {
    .cid-rrLmmgZfdP .navbar-expand {
        flex-direction: column;
        -webkit-flex-direction: column;
    }
    .cid-rrLmmgZfdP img {
        height: 3.8rem !important;
    }
    .cid-rrLmmgZfdP .btn {
        display: -webkit-flex;
    }
    .cid-rrLmmgZfdP button.navbar-toggler {
        display: block;
    }
    .cid-rrLmmgZfdP .navbar-brand {
        margin-left: 1rem !important;
    }
    .cid-rrLmmgZfdP .navbar-toggleable-sm {
        flex-direction: column;
        -webkit-flex-direction: column;
    }
    .cid-rrLmmgZfdP .navbar-collapse {
        display: none !important;
        padding-right: 0 !important;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing,
    .cid-rrLmmgZfdP .navbar-collapse.show {
        display: block !important;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-nav,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-nav {
        display: block;
        text-align: center;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-nav .nav-item,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-nav .nav-item {
        clear: both;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-buttons,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-buttons {
        text-align: center;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-buttons:last-child,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-buttons:last-child {
        margin-bottom: 1rem;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-menu {
        width: 100%;
        text-align: center;
        position: relative;
        opacity: 0;
        overflow: hidden;
        display: block;
        height: 0;
        visibility: hidden;
        padding: 0;
        transition-duration: .5s;
        transition-property: opacity, padding, height;
    }
    .cid-rrLmmgZfdP .dropdown.open>.dropdown-menu {
        position: relative;
        opacity: 1;
        height: auto;
        padding: 1.4rem 0;
        visibility: visible;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-submenu {
        left: 0;
        text-align: center;
        width: 100%;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
        margin-top: 0;
        position: inherit;
        right: 0;
        top: 50%;
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: .3em;
        vertical-align: middle;
        content: "";
        border-top: .30em solid;
        border-right: .30em solid transparent;
        border-left: .30em solid transparent;
    }
}

@media (min-width: 767px) {
    .cid-rrLmmgZfdP .menu-logo {
        flex-shrink: 0;
        -webkit-flex-shrink: 0;
    }
}

.cid-rrLmmgZfdP .navbar-collapse {
    flex-basis: auto;
    -webkit-flex-basis: auto;
}

.cid-rrLmmgZfdP .nav-link:hover,
.cid-rrLmmgZfdP .dropdown-item:hover {
    color: #c1c1c1 !important;
}

.cid-rrLSnnh0A9 {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #141414;
}

.cid-rrLSnnh0A9 .mbr-slider .carousel-control {
    background: #1b1b1b;
}

.cid-rrLSnnh0A9 .mbr-slider .carousel-control-prev {
    left: 0;
    margin-left: 2.5rem;
}

.cid-rrLSnnh0A9 .mbr-slider .carousel-control-next {
    right: 0;
    margin-right: 2.5rem;
}

.cid-rrLSnnh0A9 .mbr-slider .modal-body .close {
    background: #1b1b1b;
}

.cid-rrLSnnh0A9 .row>.row {
    display: block;
}

.cid-rrLSnnh0A9 .mbr-gallery-item {
    width: 100%;
}

.cid-rrLSnnh0A9 .mbr-gallery-item>div::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #554346;
    opacity: 0;
    -webkit-transition: 0.2s opacity ease-in-out;
    transition: 0.2s opacity ease-in-out;
}

.cid-rrLSnnh0A9 .mbr-gallery-item>div {
    position: relative;
}

.cid-rrLSnnh0A9 .mbr-gallery-item>div:hover .mbr-gallery-title::before {
    background: transparent !important;
}

.cid-rrLSnnh0A9 .mbr-gallery-item>div:hover:before {
    opacity: 0 !important;
}

.cid-rrLSnnh0A9 .mbr-gallery-title {
    font-size: .9em;
    position: absolute;
    display: block;
    width: 100%;
    bottom: 0;
    padding: 1rem;
    color: #fff;
    z-index: 2;
}

.cid-rrLSnnh0A9 .mbr-gallery-title:before {
    content: " ";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    position: absolute;
    background: #554346 !important;
    opacity: 0;
    -webkit-transition: 0.2s background ease-in-out;
    transition: 0.2s background ease-in-out;
}

.cid-rrLxDiHwOn {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #000000;
}

.cid-rrLxDiHwOn .media-container-row .mbr-text {
    color: #707070;
}

.cid-rrLmmgZfdP .navbar {
    padding: .5rem 0;
    background: #141414;
    transition: none;
    min-height: 77px;
}

.cid-rrLmmgZfdP .navbar-dropdown.bg-color.transparent.opened {
    background: #141414;
}

.cid-rrLmmgZfdP a {
    font-style: normal;
}

.cid-rrLmmgZfdP .nav-item span {
    padding-right: 0.4em;
    line-height: 0.5em;
    vertical-align: text-bottom;
    position: relative;
    text-decoration: none;
}

.cid-rrLmmgZfdP .nav-item a {
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem 0 !important;
    margin: 0rem .65rem !important;
    -webkit-align-items: center;
    -webkit-justify-content: center;
}

.cid-rrLmmgZfdP .nav-item:focus,
.cid-rrLmmgZfdP .nav-link:focus {
    outline: none;
}

.cid-rrLmmgZfdP .btn {
    padding: 0.4rem 1.5rem;
    display: -webkit-inline-flex;
    align-items: center;
    -webkit-align-items: center;
}

.cid-rrLmmgZfdP .btn .mbr-iconfont {
    font-size: 1.6rem;
}

.cid-rrLmmgZfdP .menu-logo {
    margin-right: auto;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand {
    display: flex;
    margin-left: 5rem;
    padding: 0;
    transition: padding .2s;
    min-height: 3.8rem;
    -webkit-align-items: center;
    align-items: center;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-caption-wrap {
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    word-break: break-word;
    min-width: 7rem;
    margin: .3rem 0;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption {
    line-height: 1.2rem !important;
    padding-right: 2rem;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo {
    /*@durvalpcnfont-size: 4rem;*/
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo img {
    display: flex;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo .mbr-iconfont {
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo a {
    display: inline-flex;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse {
    justify-content: center;
    -webkit-justify-content: center;
    padding-right: 5rem;
    width: auto;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-nav {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    padding-left: 0;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item {
    -webkit-align-self: center;
    align-self: center;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-buttons {
    padding-left: 0;
    padding-bottom: 0;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu {
    background: #141414;
    display: none;
    position: absolute;
    min-width: 5rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    text-align: left;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-item {
    width: auto;
    padding: 0.235em 1.5385em 0.235em 1.5385em !important;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-item::after {
    right: 0.5rem;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-submenu {
    margin: 0;
}

.cid-rrLmmgZfdP .dropdown.open>.dropdown-menu {
    display: block;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm.opened:after {
    position: absolute;
    width: 100vw;
    height: 100vh;
    content: '';
    background-color: rgba(0, 0, 0, 0.1);
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    z-index: 1000;
}

.cid-rrLmmgZfdP .navbar.navbar-short {
    min-height: 60px;
    transition: all .2s;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-toggler-right {
    top: 20px;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a {
    font-size: 2.5rem !important;
    line-height: 2.5rem;
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a .mbr-iconfont {
    font-size: 2.5rem !important;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a img {
    height: 3rem !important;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-brand {
    min-height: 3rem;
}

.cid-rrLmmgZfdP button.navbar-toggler {
    width: 31px;
    height: 18px;
    cursor: pointer;
    transition: all .2s;
    top: 1.5rem;
    right: 1rem;
}

.cid-rrLmmgZfdP button.navbar-toggler:focus {
    outline: none;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span {
    position: absolute;
    right: 0;
    width: 30px;
    height: 2px;
    border-right: 5px;
    background-color: #ffffff;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(1) {
    top: 0;
    transition: all .2s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(2) {
    top: 8px;
    transition: all .15s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(3) {
    top: 8px;
    transition: all .15s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(4) {
    top: 16px;
    transition: all .2s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(1) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all .25s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: all .25s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(4) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
}

.cid-rrLmmgZfdP .collapsed.navbar-expand {
    flex-direction: column;
    -webkit-flex-direction: column;
}

.cid-rrLmmgZfdP .collapsed .btn {
    display: -webkit-flex;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse {
    display: none !important;
    padding-right: 0 !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show {
    display: block !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-nav,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-nav {
    display: block;
    text-align: center;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-nav .nav-item {
    clear: both;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-buttons,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-buttons {
    text-align: center;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-buttons:last-child {
    margin-bottom: 1rem;
}

@media (min-width: 992px) {
    .cid-rrLmmgZfdP .collapsed:not(.navbar-short) .navbar-collapse {
        max-height: calc(98.5vh - 3.8rem);
    }
}

.cid-rrLmmgZfdP .collapsed button.navbar-toggler {
    display: block;
}

.cid-rrLmmgZfdP .collapsed .navbar-brand {
    margin-left: 1rem !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-toggleable-sm {
    flex-direction: column;
    -webkit-flex-direction: column;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-menu {
    width: 100%;
    text-align: center;
    position: relative;
    opacity: 0;
    overflow: hidden;
    display: block;
    height: 0;
    visibility: hidden;
    padding: 0;
    transition-duration: .5s;
    transition-property: opacity, padding, height;
}

.cid-rrLmmgZfdP .collapsed .dropdown.open>.dropdown-menu {
    position: relative;
    opacity: 1;
    height: auto;
    padding: 1.4rem 0;
    visibility: visible;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-submenu {
    left: 0;
    text-align: center;
    width: 100%;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
    margin-top: 0;
    position: inherit;
    right: 0;
    top: 50%;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .3em;
    vertical-align: middle;
    content: "";
    border-top: .30em solid;
    border-right: .30em solid transparent;
    border-left: .30em solid transparent;
}

@media (max-width: 991px) {
    .cid-rrLmmgZfdP .navbar-expand {
        flex-direction: column;
        -webkit-flex-direction: column;
    }
    .cid-rrLmmgZfdP img {
        height: 3.8rem !important;
    }
    .cid-rrLmmgZfdP .btn {
        display: -webkit-flex;
    }
    .cid-rrLmmgZfdP button.navbar-toggler {
        display: block;
    }
    .cid-rrLmmgZfdP .navbar-brand {
        margin-left: 1rem !important;
    }
    .cid-rrLmmgZfdP .navbar-toggleable-sm {
        flex-direction: column;
        -webkit-flex-direction: column;
    }
    .cid-rrLmmgZfdP .navbar-collapse {
        display: none !important;
        padding-right: 0 !important;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing,
    .cid-rrLmmgZfdP .navbar-collapse.show {
        display: block !important;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-nav,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-nav {
        display: block;
        text-align: center;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-nav .nav-item,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-nav .nav-item {
        clear: both;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-buttons,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-buttons {
        text-align: center;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-buttons:last-child,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-buttons:last-child {
        margin-bottom: 1rem;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-menu {
        width: 100%;
        text-align: center;
        position: relative;
        opacity: 0;
        overflow: hidden;
        display: block;
        height: 0;
        visibility: hidden;
        padding: 0;
        transition-duration: .5s;
        transition-property: opacity, padding, height;
    }
    .cid-rrLmmgZfdP .dropdown.open>.dropdown-menu {
        position: relative;
        opacity: 1;
        height: auto;
        padding: 1.4rem 0;
        visibility: visible;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-submenu {
        left: 0;
        text-align: center;
        width: 100%;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
        margin-top: 0;
        position: inherit;
        right: 0;
        top: 50%;
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: .3em;
        vertical-align: middle;
        content: "";
        border-top: .30em solid;
        border-right: .30em solid transparent;
        border-left: .30em solid transparent;
    }
}

@media (min-width: 767px) {
    .cid-rrLmmgZfdP .menu-logo {
        flex-shrink: 0;
        -webkit-flex-shrink: 0;
    }
}

.cid-rrLmmgZfdP .navbar-collapse {
    flex-basis: auto;
    -webkit-flex-basis: auto;
}

.cid-rrLmmgZfdP .nav-link:hover,
.cid-rrLmmgZfdP .dropdown-item:hover {
    color: #c1c1c1 !important;
}

.cid-rrLUDFzauW {
    padding-top: 120px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrLUDFzauW .mbr-section-subtitle {
    color: white;
}

.cid-rrLUDFzauW H2 {
    color: #9eff00;
}

.cid-rrLULeQWwM {
    background: #131313;
}

.cid-rrLULeQWwM .image-block {
    margin: auto;
    width: 100% !important;
}

.cid-rrLULeQWwM .mbr-figure {
    margin: 0 auto;
}

.cid-rrLULeQWwM figcaption {
    position: relative;
}

.cid-rrLULeQWwM figcaption div {
    position: absolute;
    bottom: 0;
    width: 100%;
}

@media (max-width: 768px) {
    .cid-rrLULeQWwM .image-block {
        width: 100% !important;
    }
}

.cid-rrLV3MH11p {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #141414;
}

.cid-rrLV3MH11p h4 {
    font-weight: 500;
    margin-bottom: 0;
    text-align: left;
}

.cid-rrLV3MH11p p {
    color: white;
    text-align: left;
}

.cid-rrLV3MH11p .card-box {
    padding-top: 2rem;
}

.cid-rrLV3MH11p .card-wrapper {
    height: 100%;
}

.cid-rrLV3MH11p .card-title {
    color: #9eff00;
}

.cid-rrLVzzgHgt {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrLVzzgHgt .card-box {
    background-color: #141414;
    padding: 2rem;
}

.cid-rrLVzzgHgt h4 {
    font-weight: 500;
    margin-bottom: 0;
    text-align: left;
}

.cid-rrLVzzgHgt p {
    color: white;
    text-align: left;
}

.cid-rrLVzzgHgt .card-wrapper {
    position: relative;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s;
}

.cid-rrLVzzgHgt .card-wrapper:hover {
    /*box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s;*/
}

.cid-rrLVzzgHgt .card-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.cid-rrLVzzgHgt P {
    text-align: left;
}

.cid-rrLVM9DFWf {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrLWFnTHEl {
    padding-top: 30px;
    padding-bottom: 15px;
    background-color: #141414;
}

.cid-rrLWFnTHEl .mbr-section-subtitle {
    color: white;
}

.cid-rrLWFnTHEl H2 {
    color: #9eff00;
}

.cid-rrLWTHUVER {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #141414;
    color: black;
}

.cid-rrLWTHUVER .mbr-section-subtitle {
    color: black;
}

.cid-rrLWTHUVER .container-table {
    margin: 0 auto;
}

.cid-rrLWTHUVER .scroll {
    overflow-x: auto;
    padding: 0;
}

.cid-rrLWTHUVER .dataTables_wrapper {
    display: block;
}

.cid-rrLWTHUVER .dataTables_wrapper .search {
    margin-bottom: .5rem;
}

.cid-rrLWTHUVER .dataTables_wrapper .table {
    overflow-x: auto;
}

.cid-rrLWTHUVER table {
    width: 100% !important;
    margin-top: 6px;
    border: 1px solid #9eff00;
    margin-bottom: 0;
}

.cid-rrLWTHUVER table th {
    border-top: none;
    transition: all .2s;
    border-bottom: none;
}

.cid-rrLWTHUVER table th:hover {
    background: #9eff00;
    color: #000000;
}

.cid-rrLWTHUVER table td {
    border-top: 1px solid #9eff00;
}

.cid-rrLWTHUVER table.table {
    background: #000000;
}

.cid-rrLWTHUVER .dataTables_filter {
    text-align: right;
    margin-bottom: .5rem;
}

.cid-rrLWTHUVER .dataTables_filter label {
    display: inline;
    white-space: normal !important;
}

.cid-rrLWTHUVER .dataTables_filter input {
    display: inline;
    width: auto;
    margin-left: .5rem;
    border-radius: 100px;
    padding-left: 1rem;
}

.cid-rrLWTHUVER .dataTables_info {
    padding-bottom: 1rem;
    padding-top: 1rem;
    white-space: normal !important;
}

@media (max-width: 992px) {
    .cid-rrLWTHUVER .dataTables_filter {
        text-align: center;
    }
}

@media (max-width: 350px) {
    .cid-rrLWTHUVER .dataTables_filter {
        text-align: center;
    }
    .cid-rrLWTHUVER .dataTables_filter input {
        width: 100% !important;
        margin-left: 0 !important;
    }
}

.cid-rrLWTHUVER .body-item {
    color: #ffffff;
    text-align: center;
}

.cid-rrM0LrwUqn {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrM0LrwUqn .mbr-section-subtitle {
    color: white;
}

.cid-rrM0LrwUqn H2 {
    color: #9eff00;
}

.cid-rrM16XtVUj {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #141414;
}

.cid-rrM16XtVUj .mbr-text {
    color: white;
}

.cid-rrM16XtVUj h4 {
    text-align: center;
}

.cid-rrM16XtVUj p {
    text-align: center;
}

.cid-rrM16XtVUj .card-img span {
    font-size: 96px;
    color: #149dcc;
}

.cid-rrM16XtVUj .card-title,
.cid-rrM16XtVUj .card-img {
    color: #ffffff;
}

.cid-rrM1vPAlT9 {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #141414;
}

.cid-rrM1vPAlT9 .mbr-text {
    color: white;
}

.cid-rrM1vPAlT9 h4 {
    text-align: center;
}

.cid-rrM1vPAlT9 p {
    text-align: center;
}

.cid-rrM1vPAlT9 .card-img span {
    font-size: 96px;
    color: #149dcc;
}

.cid-rrM1vPAlT9 .card-title,
.cid-rrM1vPAlT9 .card-img {
    color: #ffffff;
}

.cid-rrM1G55buQ {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrM1G55buQ .mbr-section-subtitle {
    color: white;
}

.cid-rrM1G55buQ H2 {
    color: #9eff00;
}

.cid-rrM1NQnFsj {
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
    background-color: #141414;
}

.cid-rrM1NQnFsj .card {
    border-radius: 0px;
    margin-bottom: -1px;
}

.cid-rrM1NQnFsj .card .card-header {
    border-radius: 0px;
    border: 0px;
    padding: 0;
}

.cid-rrM1NQnFsj .card .card-header a.panel-title {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    display: block;
    text-decoration: none !important;
    margin-top: -1px;
    line-height: normal;
}

.cid-rrM1NQnFsj .card .card-header a.panel-title:focus {
    text-decoration: none !important;
}

.cid-rrM1NQnFsj .card .card-header a.panel-title h4 {
    padding: 1.3rem 2rem;
    border: 2px solid #dfdfdf;
    margin-bottom: 0;
}

.cid-rrM1NQnFsj .card .card-header a.panel-title h4 .sign {
    padding-right: 1rem;
}

.cid-rrM1NQnFsj .card .panel-body {
    color: white;
}

.cid-rrM1NQnFsj H4 {
    color: #ffffff;
}

.cid-rrM2rZLA6O {
    padding-top: 30px;
    padding-bottom: 0px;
    background-color: #141414;
}

.cid-rrM2rZLA6O .mbr-section-subtitle {
    color: white;
}

.cid-rrM2rZLA6O H2 {
    color: #9eff00;
}

.cid-rrM2wTQvCK {
    padding-top: 0px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrLxDiHwOn {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #000000;
}

.cid-rrLxDiHwOn .media-container-row .mbr-text {
    color: #707070;
}

.cid-rrMnPDGzAL {
    padding-top: 120px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrMnPDGzAL .mbr-section-subtitle {
    color: white;
}

.cid-rrMnPDGzAL H2 {
    color: #9eff00;
}

.cid-rrMohlw9cK {
    padding-top: 30px;
    padding-bottom: 15px;
    background-color: #141414;
}

.cid-rrMohlw9cK .mbr-section-subtitle {
    color: white;
}

.cid-rrMohlw9cK H2 {
    color: #9eff00;
}

.cid-rrLmmgZfdP .navbar {
    padding: .5rem 0;
    background: #141414;
    transition: none;
    min-height: 77px;
}

.cid-rrLmmgZfdP .navbar-dropdown.bg-color.transparent.opened {
    background: #141414;
}

.cid-rrLmmgZfdP a {
    font-style: normal;
}

.cid-rrLmmgZfdP .nav-item span {
    padding-right: 0.4em;
    line-height: 0.5em;
    vertical-align: text-bottom;
    position: relative;
    text-decoration: none;
}

.cid-rrLmmgZfdP .nav-item a {
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem 0 !important;
    margin: 0rem .65rem !important;
    -webkit-align-items: center;
    -webkit-justify-content: center;
}

.cid-rrLmmgZfdP .nav-item:focus,
.cid-rrLmmgZfdP .nav-link:focus {
    outline: none;
}

.cid-rrLmmgZfdP .btn {
    padding: 0.4rem 1.5rem;
    display: -webkit-inline-flex;
    align-items: center;
    -webkit-align-items: center;
}

.cid-rrLmmgZfdP .btn .mbr-iconfont {
    font-size: 1.6rem;
}

.cid-rrLmmgZfdP .menu-logo {
    margin-right: auto;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand {
    display: flex;
    margin-left: 5rem;
    padding: 0;
    transition: padding .2s;
    min-height: 3.8rem;
    -webkit-align-items: center;
    align-items: center;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-caption-wrap {
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    word-break: break-word;
    min-width: 7rem;
    margin: .3rem 0;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption {
    line-height: 1.2rem !important;
    padding-right: 2rem;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo {
    /*@durvalpcnfont-size: 4rem;*/
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo img {
    display: flex;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo .mbr-iconfont {
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo a {
    display: inline-flex;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse {
    justify-content: center;
    -webkit-justify-content: center;
    padding-right: 5rem;
    width: auto;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-nav {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    padding-left: 0;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item {
    -webkit-align-self: center;
    align-self: center;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-buttons {
    padding-left: 0;
    padding-bottom: 0;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu {
    background: #141414;
    display: none;
    position: absolute;
    min-width: 5rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    text-align: left;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-item {
    width: auto;
    padding: 0.235em 1.5385em 0.235em 1.5385em !important;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-item::after {
    right: 0.5rem;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-submenu {
    margin: 0;
}

.cid-rrLmmgZfdP .dropdown.open>.dropdown-menu {
    display: block;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm.opened:after {
    position: absolute;
    width: 100vw;
    height: 100vh;
    content: '';
    background-color: rgba(0, 0, 0, 0.1);
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    z-index: 1000;
}

.cid-rrLmmgZfdP .navbar.navbar-short {
    min-height: 60px;
    transition: all .2s;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-toggler-right {
    top: 20px;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a {
    font-size: 2.5rem !important;
    line-height: 2.5rem;
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a .mbr-iconfont {
    font-size: 2.5rem !important;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a img {
    height: 3rem !important;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-brand {
    min-height: 3rem;
}

.cid-rrLmmgZfdP button.navbar-toggler {
    width: 31px;
    height: 18px;
    cursor: pointer;
    transition: all .2s;
    top: 1.5rem;
    right: 1rem;
}

.cid-rrLmmgZfdP button.navbar-toggler:focus {
    outline: none;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span {
    position: absolute;
    right: 0;
    width: 30px;
    height: 2px;
    border-right: 5px;
    background-color: #ffffff;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(1) {
    top: 0;
    transition: all .2s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(2) {
    top: 8px;
    transition: all .15s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(3) {
    top: 8px;
    transition: all .15s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(4) {
    top: 16px;
    transition: all .2s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(1) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all .25s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: all .25s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(4) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
}

.cid-rrLmmgZfdP .collapsed.navbar-expand {
    flex-direction: column;
    -webkit-flex-direction: column;
}

.cid-rrLmmgZfdP .collapsed .btn {
    display: -webkit-flex;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse {
    display: none !important;
    padding-right: 0 !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show {
    display: block !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-nav,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-nav {
    display: block;
    text-align: center;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-nav .nav-item {
    clear: both;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-buttons,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-buttons {
    text-align: center;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-buttons:last-child {
    margin-bottom: 1rem;
}

@media (min-width: 992px) {
    .cid-rrLmmgZfdP .collapsed:not(.navbar-short) .navbar-collapse {
        max-height: calc(98.5vh - 3.8rem);
    }
}

.cid-rrLmmgZfdP .collapsed button.navbar-toggler {
    display: block;
}

.cid-rrLmmgZfdP .collapsed .navbar-brand {
    margin-left: 1rem !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-toggleable-sm {
    flex-direction: column;
    -webkit-flex-direction: column;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-menu {
    width: 100%;
    text-align: center;
    position: relative;
    opacity: 0;
    overflow: hidden;
    display: block;
    height: 0;
    visibility: hidden;
    padding: 0;
    transition-duration: .5s;
    transition-property: opacity, padding, height;
}

.cid-rrLmmgZfdP .collapsed .dropdown.open>.dropdown-menu {
    position: relative;
    opacity: 1;
    height: auto;
    padding: 1.4rem 0;
    visibility: visible;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-submenu {
    left: 0;
    text-align: center;
    width: 100%;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
    margin-top: 0;
    position: inherit;
    right: 0;
    top: 50%;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .3em;
    vertical-align: middle;
    content: "";
    border-top: .30em solid;
    border-right: .30em solid transparent;
    border-left: .30em solid transparent;
}

@media (max-width: 991px) {
    .cid-rrLmmgZfdP .navbar-expand {
        flex-direction: column;
        -webkit-flex-direction: column;
    }
    .cid-rrLmmgZfdP img {
        height: 3.8rem !important;
    }
    .cid-rrLmmgZfdP .btn {
        display: -webkit-flex;
    }
    .cid-rrLmmgZfdP button.navbar-toggler {
        display: block;
    }
    .cid-rrLmmgZfdP .navbar-brand {
        margin-left: 1rem !important;
    }
    .cid-rrLmmgZfdP .navbar-toggleable-sm {
        flex-direction: column;
        -webkit-flex-direction: column;
    }
    .cid-rrLmmgZfdP .navbar-collapse {
        display: none !important;
        padding-right: 0 !important;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing,
    .cid-rrLmmgZfdP .navbar-collapse.show {
        display: block !important;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-nav,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-nav {
        display: block;
        text-align: center;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-nav .nav-item,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-nav .nav-item {
        clear: both;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-buttons,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-buttons {
        text-align: center;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-buttons:last-child,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-buttons:last-child {
        margin-bottom: 1rem;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-menu {
        width: 100%;
        text-align: center;
        position: relative;
        opacity: 0;
        overflow: hidden;
        display: block;
        height: 0;
        visibility: hidden;
        padding: 0;
        transition-duration: .5s;
        transition-property: opacity, padding, height;
    }
    .cid-rrLmmgZfdP .dropdown.open>.dropdown-menu {
        position: relative;
        opacity: 1;
        height: auto;
        padding: 1.4rem 0;
        visibility: visible;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-submenu {
        left: 0;
        text-align: center;
        width: 100%;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
        margin-top: 0;
        position: inherit;
        right: 0;
        top: 50%;
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: .3em;
        vertical-align: middle;
        content: "";
        border-top: .30em solid;
        border-right: .30em solid transparent;
        border-left: .30em solid transparent;
    }
}

@media (min-width: 767px) {
    .cid-rrLmmgZfdP .menu-logo {
        flex-shrink: 0;
        -webkit-flex-shrink: 0;
    }
}

.cid-rrLmmgZfdP .navbar-collapse {
    flex-basis: auto;
    -webkit-flex-basis: auto;
}

.cid-rrLmmgZfdP .nav-link:hover,
.cid-rrLmmgZfdP .dropdown-item:hover {
    color: #c1c1c1 !important;
}

.cid-rrMosiC4TA {
    padding-top: 0px;
    padding-bottom: 0px;
    position: relative;
    background-color: #141414;
}

.cid-rrMosiC4TA .card {
    border-radius: 0px;
    margin-bottom: -1px;
}

.cid-rrMosiC4TA .card .card-header {
    border-radius: 0px;
    border: 0px;
    padding: 0;
}

.cid-rrMosiC4TA .card .card-header a.panel-title {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    display: block;
    text-decoration: none !important;
    margin-top: -1px;
    line-height: normal;
}

.cid-rrMosiC4TA .card .card-header a.panel-title:focus {
    text-decoration: none !important;
}

.cid-rrMosiC4TA .card .card-header a.panel-title h4 {
    padding: 1.3rem 2rem;
    border: 2px solid #dfdfdf;
    margin-bottom: 0;
}

.cid-rrMosiC4TA .card .card-header a.panel-title h4 .sign {
    padding-right: 1rem;
}

.cid-rrMosiC4TA .card .panel-body {
    color: white;
}

.cid-rrMosiC4TA H4 {
    color: #ffffff;
}

.cid-rrMpslhnJN {
    padding-top: 0px;
    padding-bottom: 0px;
    position: relative;
    background-color: #141414;
}

.cid-rrMpslhnJN .card {
    border-radius: 0px;
    margin-bottom: -1px;
}

.cid-rrMpslhnJN .card .card-header {
    border-radius: 0px;
    border: 0px;
    padding: 0;
}

.cid-rrMpslhnJN .card .card-header a.panel-title {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    display: block;
    text-decoration: none !important;
    margin-top: -1px;
    line-height: normal;
}

.cid-rrMpslhnJN .card .card-header a.panel-title:focus {
    text-decoration: none !important;
}

.cid-rrMpslhnJN .card .card-header a.panel-title h4 {
    padding: 1.3rem 2rem;
    border: 1px solid #dfdfdf;
    margin-bottom: 0;
}

.cid-rrMpslhnJN .card .card-header a.panel-title h4 .sign {
    padding-right: 1rem;
}

.cid-rrMpslhnJN .card .panel-body {
    color: white;
}

.cid-rrMpslhnJN H4 {
    color: #ffffff;
}

.cid-rrMpKqfA9A {
    padding-top: 0px;
    padding-bottom: 0px;
    position: relative;
    background-color: #141414;
}

.cid-rrMpKqfA9A .card {
    border-radius: 0px;
    margin-bottom: -1px;
}

.cid-rrMpKqfA9A .card .card-header {
    border-radius: 0px;
    border: 0px;
    padding: 0;
}

.cid-rrMpKqfA9A .card .card-header a.panel-title {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    display: block;
    text-decoration: none !important;
    margin-top: -1px;
    line-height: normal;
}

.cid-rrMpKqfA9A .card .card-header a.panel-title:focus {
    text-decoration: none !important;
}

.cid-rrMpKqfA9A .card .card-header a.panel-title h4 {
    padding: 1.3rem 2rem;
    border: 1px solid #dfdfdf;
    margin-bottom: 0;
}

.cid-rrMpKqfA9A .card .card-header a.panel-title h4 .sign {
    padding-right: 1rem;
}

.cid-rrMpKqfA9A .card .panel-body {
    color: white;
}

.cid-rrMpKqfA9A H4 {
    color: #ffffff;
}

.cid-rrMqbxVHVm {
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
    background-color: #141414;
}

.cid-rrMqbxVHVm .card {
    border-radius: 0px;
    margin-bottom: -1px;
}

.cid-rrMqbxVHVm .card .card-header {
    border-radius: 0px;
    border: 0px;
    padding: 0;
}

.cid-rrMqbxVHVm .card .card-header a.panel-title {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    display: block;
    text-decoration: none !important;
    margin-top: -1px;
    line-height: normal;
}

.cid-rrMqbxVHVm .card .card-header a.panel-title:focus {
    text-decoration: none !important;
}

.cid-rrMqbxVHVm .card .card-header a.panel-title h4 {
    padding: 1.3rem 2rem;
    border: 1px solid #dfdfdf;
    margin-bottom: 0;
}

.cid-rrMqbxVHVm .card .card-header a.panel-title h4 .sign {
    padding-right: 1rem;
}

.cid-rrMqbxVHVm .card .panel-body {
    color: white;
}

.cid-rrMqbxVHVm H4 {
    color: #ffffff;
}

.cid-rrLxDiHwOn {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #000000;
}

.cid-rrLxDiHwOn .media-container-row .mbr-text {
    color: #707070;
}

.cid-rrMqQkeZ9V {
    padding-top: 120px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrMqQkeZ9V .mbr-section-subtitle {
    color: white;
}

.cid-rrMqQkeZ9V H2 {
    color: #9eff00;
}

.cid-rrLmmgZfdP .navbar {
    padding: .5rem 0;
    background: #141414;
    transition: none;
    min-height: 77px;
}

.cid-rrLmmgZfdP .navbar-dropdown.bg-color.transparent.opened {
    background: #141414;
}

.cid-rrLmmgZfdP a {
    font-style: normal;
}

.cid-rrLmmgZfdP .nav-item span {
    padding-right: 0.4em;
    line-height: 0.5em;
    vertical-align: text-bottom;
    position: relative;
    text-decoration: none;
}

.cid-rrLmmgZfdP .nav-item a {
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem 0 !important;
    margin: 0rem .65rem !important;
    -webkit-align-items: center;
    -webkit-justify-content: center;
}

.cid-rrLmmgZfdP .nav-item:focus,
.cid-rrLmmgZfdP .nav-link:focus {
    outline: none;
}

.cid-rrLmmgZfdP .btn {
    padding: 0.4rem 1.5rem;
    display: -webkit-inline-flex;
    align-items: center;
    -webkit-align-items: center;
}

.cid-rrLmmgZfdP .btn .mbr-iconfont {
    font-size: 1.6rem;
}

.cid-rrLmmgZfdP .menu-logo {
    margin-right: auto;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand {
    display: flex;
    margin-left: 5rem;
    padding: 0;
    transition: padding .2s;
    min-height: 3.8rem;
    -webkit-align-items: center;
    align-items: center;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-caption-wrap {
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    word-break: break-word;
    min-width: 7rem;
    margin: .3rem 0;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption {
    line-height: 1.2rem !important;
    padding-right: 2rem;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo {
    /*@durvalpcnfont-size: 4rem;*/
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo img {
    display: flex;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo .mbr-iconfont {
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo a {
    display: inline-flex;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse {
    justify-content: center;
    -webkit-justify-content: center;
    padding-right: 5rem;
    width: auto;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-nav {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    padding-left: 0;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item {
    -webkit-align-self: center;
    align-self: center;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-buttons {
    padding-left: 0;
    padding-bottom: 0;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu {
    background: #141414;
    display: none;
    position: absolute;
    min-width: 5rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    text-align: left;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-item {
    width: auto;
    padding: 0.235em 1.5385em 0.235em 1.5385em !important;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-item::after {
    right: 0.5rem;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-submenu {
    margin: 0;
}

.cid-rrLmmgZfdP .dropdown.open>.dropdown-menu {
    display: block;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm.opened:after {
    position: absolute;
    width: 100vw;
    height: 100vh;
    content: '';
    background-color: rgba(0, 0, 0, 0.1);
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    z-index: 1000;
}

.cid-rrLmmgZfdP .navbar.navbar-short {
    min-height: 60px;
    transition: all .2s;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-toggler-right {
    top: 20px;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a {
    font-size: 2.5rem !important;
    line-height: 2.5rem;
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a .mbr-iconfont {
    font-size: 2.5rem !important;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a img {
    height: 3rem !important;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-brand {
    min-height: 3rem;
}

.cid-rrLmmgZfdP button.navbar-toggler {
    width: 31px;
    height: 18px;
    cursor: pointer;
    transition: all .2s;
    top: 1.5rem;
    right: 1rem;
}

.cid-rrLmmgZfdP button.navbar-toggler:focus {
    outline: none;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span {
    position: absolute;
    right: 0;
    width: 30px;
    height: 2px;
    border-right: 5px;
    background-color: #ffffff;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(1) {
    top: 0;
    transition: all .2s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(2) {
    top: 8px;
    transition: all .15s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(3) {
    top: 8px;
    transition: all .15s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(4) {
    top: 16px;
    transition: all .2s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(1) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all .25s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: all .25s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(4) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
}

.cid-rrLmmgZfdP .collapsed.navbar-expand {
    flex-direction: column;
    -webkit-flex-direction: column;
}

.cid-rrLmmgZfdP .collapsed .btn {
    display: -webkit-flex;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse {
    display: none !important;
    padding-right: 0 !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show {
    display: block !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-nav,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-nav {
    display: block;
    text-align: center;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-nav .nav-item {
    clear: both;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-buttons,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-buttons {
    text-align: center;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-buttons:last-child {
    margin-bottom: 1rem;
}

@media (min-width: 992px) {
    .cid-rrLmmgZfdP .collapsed:not(.navbar-short) .navbar-collapse {
        max-height: calc(98.5vh - 3.8rem);
    }
}

.cid-rrLmmgZfdP .collapsed button.navbar-toggler {
    display: block;
}

.cid-rrLmmgZfdP .collapsed .navbar-brand {
    margin-left: 1rem !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-toggleable-sm {
    flex-direction: column;
    -webkit-flex-direction: column;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-menu {
    width: 100%;
    text-align: center;
    position: relative;
    opacity: 0;
    overflow: hidden;
    display: block;
    height: 0;
    visibility: hidden;
    padding: 0;
    transition-duration: .5s;
    transition-property: opacity, padding, height;
}

.cid-rrLmmgZfdP .collapsed .dropdown.open>.dropdown-menu {
    position: relative;
    opacity: 1;
    height: auto;
    padding: 1.4rem 0;
    visibility: visible;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-submenu {
    left: 0;
    text-align: center;
    width: 100%;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
    margin-top: 0;
    position: inherit;
    right: 0;
    top: 50%;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .3em;
    vertical-align: middle;
    content: "";
    border-top: .30em solid;
    border-right: .30em solid transparent;
    border-left: .30em solid transparent;
}

@media (max-width: 991px) {
    .cid-rrLmmgZfdP .navbar-expand {
        flex-direction: column;
        -webkit-flex-direction: column;
    }
    .cid-rrLmmgZfdP img {
        height: 3.8rem !important;
    }
    .cid-rrLmmgZfdP .btn {
        display: -webkit-flex;
    }
    .cid-rrLmmgZfdP button.navbar-toggler {
        display: block;
    }
    .cid-rrLmmgZfdP .navbar-brand {
        margin-left: 1rem !important;
    }
    .cid-rrLmmgZfdP .navbar-toggleable-sm {
        flex-direction: column;
        -webkit-flex-direction: column;
    }
    .cid-rrLmmgZfdP .navbar-collapse {
        display: none !important;
        padding-right: 0 !important;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing,
    .cid-rrLmmgZfdP .navbar-collapse.show {
        display: block !important;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-nav,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-nav {
        display: block;
        text-align: center;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-nav .nav-item,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-nav .nav-item {
        clear: both;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-buttons,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-buttons {
        text-align: center;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-buttons:last-child,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-buttons:last-child {
        margin-bottom: 1rem;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-menu {
        width: 100%;
        text-align: center;
        position: relative;
        opacity: 0;
        overflow: hidden;
        display: block;
        height: 0;
        visibility: hidden;
        padding: 0;
        transition-duration: .5s;
        transition-property: opacity, padding, height;
    }
    .cid-rrLmmgZfdP .dropdown.open>.dropdown-menu {
        position: relative;
        opacity: 1;
        height: auto;
        padding: 1.4rem 0;
        visibility: visible;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-submenu {
        left: 0;
        text-align: center;
        width: 100%;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
        margin-top: 0;
        position: inherit;
        right: 0;
        top: 50%;
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: .3em;
        vertical-align: middle;
        content: "";
        border-top: .30em solid;
        border-right: .30em solid transparent;
        border-left: .30em solid transparent;
    }
}

@media (min-width: 767px) {
    .cid-rrLmmgZfdP .menu-logo {
        flex-shrink: 0;
        -webkit-flex-shrink: 0;
    }
}

.cid-rrLmmgZfdP .navbar-collapse {
    flex-basis: auto;
    -webkit-flex-basis: auto;
}

.cid-rrLmmgZfdP .nav-link:hover,
.cid-rrLmmgZfdP .dropdown-item:hover {
    color: #c1c1c1 !important;
}

.cid-rrMqZQCxgf {
    background: #141414;
    padding-top: 0px;
    padding-bottom: 0px;
}

.cid-rrMqZQCxgf .video-block {
    margin: auto;
}

@media (max-width: 768px) {
    .cid-rrMqZQCxgf .video-block {
        width: 100% !important;
    }
}

.cid-rrMs9H20CL {
    padding-top: 30px;
    padding-bottom: 0px;
    background-color: #141414;
}

.cid-rrMsoV6RGO {
    padding-top: 15px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrMsoV6RGO .mbr-section-subtitle {
    color: whit;
}

.cid-rrMsoV6RGO H2 {
    color: #9eff00;
}

.cid-rrLxDiHwOn {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #000000;
}

.cid-rrLxDiHwOn .media-container-row .mbr-text {
    color: #707070;
}

.cid-rrLmmgZfdP .navbar {
    padding: .5rem 0;
    background: #141414;
    transition: none;
    min-height: 77px;
}

.cid-rrLmmgZfdP .navbar-dropdown.bg-color.transparent.opened {
    background: #141414;
}

.cid-rrLmmgZfdP a {
    font-style: normal;
}

.cid-rrLmmgZfdP .nav-item span {
    padding-right: 0.4em;
    line-height: 0.5em;
    vertical-align: text-bottom;
    position: relative;
    text-decoration: none;
}

.cid-rrLmmgZfdP .nav-item a {
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem 0 !important;
    margin: 0rem .65rem !important;
    -webkit-align-items: center;
    -webkit-justify-content: center;
}

.cid-rrLmmgZfdP .nav-item:focus,
.cid-rrLmmgZfdP .nav-link:focus {
    outline: none;
}

.cid-rrLmmgZfdP .btn {
    padding: 0.4rem 1.5rem;
    display: -webkit-inline-flex;
    align-items: center;
    -webkit-align-items: center;
}

.cid-rrLmmgZfdP .btn .mbr-iconfont {
    font-size: 1.6rem;
}

.cid-rrLmmgZfdP .menu-logo {
    margin-right: auto;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand {
    display: flex;
    margin-left: 5rem;
    padding: 0;
    transition: padding .2s;
    min-height: 3.8rem;
    -webkit-align-items: center;
    align-items: center;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-caption-wrap {
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    word-break: break-word;
    min-width: 7rem;
    margin: .3rem 0;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption {
    line-height: 1.2rem !important;
    padding-right: 2rem;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo {
    /*@durvalpcnfont-size: 4rem;*/
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo img {
    display: flex;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo .mbr-iconfont {
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo a {
    display: inline-flex;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse {
    justify-content: center;
    -webkit-justify-content: center;
    padding-right: 5rem;
    width: auto;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-nav {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    padding-left: 0;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item {
    -webkit-align-self: center;
    align-self: center;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-buttons {
    padding-left: 0;
    padding-bottom: 0;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu {
    background: #141414;
    display: none;
    position: absolute;
    min-width: 5rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    text-align: left;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-item {
    width: auto;
    padding: 0.235em 1.5385em 0.235em 1.5385em !important;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-item::after {
    right: 0.5rem;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-submenu {
    margin: 0;
}

.cid-rrLmmgZfdP .dropdown.open>.dropdown-menu {
    display: block;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm.opened:after {
    position: absolute;
    width: 100vw;
    height: 100vh;
    content: '';
    background-color: rgba(0, 0, 0, 0.1);
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    z-index: 1000;
}

.cid-rrLmmgZfdP .navbar.navbar-short {
    min-height: 60px;
    transition: all .2s;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-toggler-right {
    top: 20px;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a {
    font-size: 2.5rem !important;
    line-height: 2.5rem;
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a .mbr-iconfont {
    font-size: 2.5rem !important;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a img {
    height: 3rem !important;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-brand {
    min-height: 3rem;
}

.cid-rrLmmgZfdP button.navbar-toggler {
    width: 31px;
    height: 18px;
    cursor: pointer;
    transition: all .2s;
    top: 1.5rem;
    right: 1rem;
}

.cid-rrLmmgZfdP button.navbar-toggler:focus {
    outline: none;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span {
    position: absolute;
    right: 0;
    width: 30px;
    height: 2px;
    border-right: 5px;
    background-color: #ffffff;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(1) {
    top: 0;
    transition: all .2s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(2) {
    top: 8px;
    transition: all .15s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(3) {
    top: 8px;
    transition: all .15s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(4) {
    top: 16px;
    transition: all .2s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(1) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all .25s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: all .25s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(4) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
}

.cid-rrLmmgZfdP .collapsed.navbar-expand {
    flex-direction: column;
    -webkit-flex-direction: column;
}

.cid-rrLmmgZfdP .collapsed .btn {
    display: -webkit-flex;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse {
    display: none !important;
    padding-right: 0 !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show {
    display: block !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-nav,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-nav {
    display: block;
    text-align: center;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-nav .nav-item {
    clear: both;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-buttons,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-buttons {
    text-align: center;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-buttons:last-child {
    margin-bottom: 1rem;
}

@media (min-width: 992px) {
    .cid-rrLmmgZfdP .collapsed:not(.navbar-short) .navbar-collapse {
        max-height: calc(98.5vh - 3.8rem);
    }
}

.cid-rrLmmgZfdP .collapsed button.navbar-toggler {
    display: block;
}

.cid-rrLmmgZfdP .collapsed .navbar-brand {
    margin-left: 1rem !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-toggleable-sm {
    flex-direction: column;
    -webkit-flex-direction: column;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-menu {
    width: 100%;
    text-align: center;
    position: relative;
    opacity: 0;
    overflow: hidden;
    display: block;
    height: 0;
    visibility: hidden;
    padding: 0;
    transition-duration: .5s;
    transition-property: opacity, padding, height;
}

.cid-rrLmmgZfdP .collapsed .dropdown.open>.dropdown-menu {
    position: relative;
    opacity: 1;
    height: auto;
    padding: 1.4rem 0;
    visibility: visible;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-submenu {
    left: 0;
    text-align: center;
    width: 100%;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
    margin-top: 0;
    position: inherit;
    right: 0;
    top: 50%;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .3em;
    vertical-align: middle;
    content: "";
    border-top: .30em solid;
    border-right: .30em solid transparent;
    border-left: .30em solid transparent;
}

@media (max-width: 991px) {
    .cid-rrLmmgZfdP .navbar-expand {
        flex-direction: column;
        -webkit-flex-direction: column;
    }
    .cid-rrLmmgZfdP img {
        height: 3.8rem !important;
    }
    .cid-rrLmmgZfdP .btn {
        display: -webkit-flex;
    }
    .cid-rrLmmgZfdP button.navbar-toggler {
        display: block;
    }
    .cid-rrLmmgZfdP .navbar-brand {
        margin-left: 1rem !important;
    }
    .cid-rrLmmgZfdP .navbar-toggleable-sm {
        flex-direction: column;
        -webkit-flex-direction: column;
    }
    .cid-rrLmmgZfdP .navbar-collapse {
        display: none !important;
        padding-right: 0 !important;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing,
    .cid-rrLmmgZfdP .navbar-collapse.show {
        display: block !important;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-nav,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-nav {
        display: block;
        text-align: center;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-nav .nav-item,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-nav .nav-item {
        clear: both;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-buttons,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-buttons {
        text-align: center;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-buttons:last-child,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-buttons:last-child {
        margin-bottom: 1rem;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-menu {
        width: 100%;
        text-align: center;
        position: relative;
        opacity: 0;
        overflow: hidden;
        display: block;
        height: 0;
        visibility: hidden;
        padding: 0;
        transition-duration: .5s;
        transition-property: opacity, padding, height;
    }
    .cid-rrLmmgZfdP .dropdown.open>.dropdown-menu {
        position: relative;
        opacity: 1;
        height: auto;
        padding: 1.4rem 0;
        visibility: visible;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-submenu {
        left: 0;
        text-align: center;
        width: 100%;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
        margin-top: 0;
        position: inherit;
        right: 0;
        top: 50%;
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: .3em;
        vertical-align: middle;
        content: "";
        border-top: .30em solid;
        border-right: .30em solid transparent;
        border-left: .30em solid transparent;
    }
}

@media (min-width: 767px) {
    .cid-rrLmmgZfdP .menu-logo {
        flex-shrink: 0;
        -webkit-flex-shrink: 0;
    }
}

.cid-rrLmmgZfdP .navbar-collapse {
    flex-basis: auto;
    -webkit-flex-basis: auto;
}

.cid-rrLmmgZfdP .nav-link:hover,
.cid-rrLmmgZfdP .dropdown-item:hover {
    color: #c1c1c1 !important;
}

.cid-rrLxDiHwOn {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #000000;
}

.cid-rrLxDiHwOn .media-container-row .mbr-text {
    color: #707070;
}

.cid-rrLmmgZfdP .navbar {
    padding: 0 0;
    background: #141414;
    transition: none;
    min-height: 77px;
}

.cid-rrLmmgZfdP2 .navbar {
    padding: .3rem 0;
    background-color: black;
    transition: none;
    min-height: 50px;
    z-index: 3;
    padding-top: 20px;
    padding-right: 15px;
    word-break: normal;
}

.cid-rrLmmgZfdP .navbar-dropdown.bg-color.transparent.opened {
    background: #141414;
}

.cid-rrLmmgZfdP a {
    font-style: normal;
}

.cid-rrLmmgZfdP .nav-item span {
    padding-right: 0.4em;
    line-height: 0.5em;
    vertical-align: text-bottom;
    position: relative;
    text-decoration: none;
}

.cid-rrLmmgZfdP .nav-item a {
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem 0 !important;
    margin: 0rem .65rem !important;
    -webkit-align-items: center;
    -webkit-justify-content: center;
}

.cid-rrLmmgZfdP .nav-item:focus,
.cid-rrLmmgZfdP .nav-link:focus {
    outline: none;
}

.cid-rrLmmgZfdP .btn {
    padding: 0.4rem 1.5rem;
    display: -webkit-inline-flex;
    align-items: center;
    -webkit-align-items: center;
}

.cid-rrLmmgZfdP .btn .mbr-iconfont {
    font-size: 1.6rem;
}

.cid-rrLmmgZfdP .menu-logo {
    margin-right: auto;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand {
    display: flex;
    margin-left: 5rem;
    padding: 0;
    transition: padding .2s;
    min-height: 3.8rem;
    -webkit-align-items: center;
    align-items: center;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-caption-wrap {
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    word-break: break-word;
    min-width: 7rem;
    margin: .3rem 0;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption {
    line-height: 1.2rem !important;
    padding-right: 2rem;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo {
    /*@durvalpcnfont-size: 4rem;*/
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo img {
    display: flex;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo .mbr-iconfont {
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .menu-logo .navbar-brand .navbar-logo a {
    display: inline-flex;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse {
    justify-content: center;
    -webkit-justify-content: center;
    padding-right: 5rem;
    width: auto;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-nav {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    padding-left: 0;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item {
    -webkit-align-self: center;
    align-self: center;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm .navbar-collapse .navbar-buttons {
    padding-left: 0;
    padding-bottom: 0;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu {
    background: #141414;
    display: none;
    position: absolute;
    min-width: 5rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    text-align: left;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-item {
    width: auto;
    padding: 0.235em 1.5385em 0.235em 1.5385em !important;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-item::after {
    right: 0.5rem;
}

.cid-rrLmmgZfdP .dropdown .dropdown-menu .dropdown-submenu {
    margin: 0;
}

.cid-rrLmmgZfdP .dropdown.open>.dropdown-menu {
    display: block;
}

.cid-rrLmmgZfdP .navbar-toggleable-sm.opened:after {
    position: absolute;
    width: 100vw;
    height: 100vh;
    content: '';
    background-color: rgba(0, 0, 0, 0.1);
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    z-index: 1000;
}

.cid-rrLmmgZfdP .navbar.navbar-short {
    min-height: 57px;
    transition: all .2s;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-toggler-right {
    top: 25px;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a {
    font-size: 2.5rem !important;
    line-height: 2.5rem;
    transition: font-size 0.25s;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a .mbr-iconfont {
    font-size: 2.5rem !important;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-logo a img {
    height: 3rem !important;
}

.cid-rrLmmgZfdP .navbar.navbar-short .navbar-brand {
    min-height: 3rem;
}

.cid-rrLmmgZfdP button.navbar-toggler {
    width: 31px;
    height: 18px;
    cursor: pointer;
    transition: all .2s;
    top: 1.5rem;
    right: 1rem;
}

.cid-rrLmmgZfdP button.navbar-toggler:focus {
    outline: none;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span {
    position: absolute;
    right: 0;
    width: 30px;
    height: 2px;
    border-right: 5px;
    background-color: #ffffff;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(1) {
    top: 0;
    transition: all .2s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(2) {
    top: 8px;
    transition: all .15s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(3) {
    top: 8px;
    transition: all .15s;
}

.cid-rrLmmgZfdP button.navbar-toggler .hamburger span:nth-child(4) {
    top: 16px;
    transition: all .2s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(1) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all .25s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: all .25s;
}

.cid-rrLmmgZfdP nav.opened .hamburger span:nth-child(4) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
}

.cid-rrLmmgZfdP .collapsed.navbar-expand {
    flex-direction: column;
    -webkit-flex-direction: column;
}

.cid-rrLmmgZfdP .collapsed .btn {
    display: -webkit-flex;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse {
    display: none !important;
    padding-right: 0 !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show {
    display: block !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-nav,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-nav {
    display: block;
    text-align: center;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-nav .nav-item {
    clear: both;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-buttons,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-buttons {
    text-align: center;
}

.cid-rrLmmgZfdP .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child,
.cid-rrLmmgZfdP .collapsed .navbar-collapse.show .navbar-buttons:last-child {
    margin-bottom: 1rem;
}

@media (min-width: 992px) {
    .cid-rrLmmgZfdP .collapsed:not(.navbar-short) .navbar-collapse {
        max-height: calc(98.5vh - 3.8rem);
    }
}

.cid-rrLmmgZfdP .collapsed button.navbar-toggler {
    display: block;
}

.cid-rrLmmgZfdP .collapsed .navbar-brand {
    margin-left: 1rem !important;
}

.cid-rrLmmgZfdP .collapsed .navbar-toggleable-sm {
    flex-direction: column;
    -webkit-flex-direction: column;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-menu {
    width: 100%;
    text-align: center;
    position: relative;
    opacity: 0;
    overflow: hidden;
    display: block;
    height: 0;
    visibility: hidden;
    padding: 0;
    transition-duration: .5s;
    transition-property: opacity, padding, height;
}

.cid-rrLmmgZfdP .collapsed .dropdown.open>.dropdown-menu {
    position: relative;
    opacity: 1;
    height: auto;
    padding: 1.4rem 0;
    visibility: visible;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-submenu {
    left: 0;
    text-align: center;
    width: 100%;
}

.cid-rrLmmgZfdP .collapsed .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
    margin-top: 0;
    position: inherit;
    right: 0;
    top: 50%;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .3em;
    vertical-align: middle;
    content: "";
    border-top: .30em solid;
    border-right: .30em solid transparent;
    border-left: .30em solid transparent;
}

@media (max-width: 991px) {
    .cid-rrLmmgZfdP .navbar-expand {
        flex-direction: column;
        -webkit-flex-direction: column;
    }
    .cid-rrLmmgZfdP img {
        height: 2.5rem !important;
    }
    .cid-rrLmmgZfdP .btn {
        display: -webkit-flex;
    }
    .cid-rrLmmgZfdP button.navbar-toggler {
        display: block;
    }
    .cid-rrLmmgZfdP .navbar-brand {
        margin-left: 1rem !important;
    }
    .cid-rrLmmgZfdP .navbar-toggleable-sm {
        flex-direction: column;
        -webkit-flex-direction: column;
    }
    .cid-rrLmmgZfdP .navbar-collapse {
        display: none !important;
        padding-right: 0 !important;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing,
    .cid-rrLmmgZfdP .navbar-collapse.show {
        display: block !important;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-nav,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-nav {
        display: block;
        text-align: center;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-nav .nav-item,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-nav .nav-item {
        clear: both;
        padding: 6px;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-buttons,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-buttons {
        text-align: center;
    }
    .cid-rrLmmgZfdP .navbar-collapse.collapsing .navbar-buttons:last-child,
    .cid-rrLmmgZfdP .navbar-collapse.show .navbar-buttons:last-child {
        margin-bottom: 1rem;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-menu {
        width: 100%;
        text-align: center;
        position: relative;
        opacity: 0;
        overflow: hidden;
        display: block;
        height: 0;
        visibility: hidden;
        padding: 0;
        transition-duration: .5s;
        transition-property: opacity, padding, height;
    }
    .cid-rrLmmgZfdP .dropdown.open>.dropdown-menu {
        position: relative;
        opacity: 1;
        height: auto;
        padding: 1.4rem 0;
        visibility: visible;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-submenu {
        left: 0;
        text-align: center;
        width: 100%;
    }
    .cid-rrLmmgZfdP .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
        margin-top: 0;
        position: inherit;
        right: 0;
        top: 50%;
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: .3em;
        vertical-align: middle;
        content: "";
        border-top: .30em solid;
        border-right: .30em solid transparent;
        border-left: .30em solid transparent;
    }
}

@media (min-width: 767px) {
    .cid-rrLmmgZfdP .menu-logo {
        flex-shrink: 0;
        -webkit-flex-shrink: 0;
    }
}

.cid-rrLmmgZfdP .navbar-collapse {
    flex-basis: auto;
    -webkit-flex-basis: auto;
}

.cid-rrLmmgZfdP .nav-link:hover,
.cid-rrLmmgZfdP .dropdown-item:hover {
    color: #c1c1c1 !important;
}

.cid-rrMsZZxmKS {
    padding-top: 120px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrMsZZxmKS .mbr-section-subtitle {
    color: white;
}

.cid-rrMsZZxmKS H2 {
    color: #9eff00;
}

.cid-rrMtDqhAPi {
    background: #141414;
    padding-top: 0px;
    padding-bottom: 0px;
}

.cid-rrMtDqhAPi .video-block {
    margin: auto;
}

@media (max-width: 768px) {
    .cid-rrMtDqhAPi .video-block {
        width: 100% !important;
    }
}

.cid-rrMtSQAJVx {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #141414;
}

.cid-rrMtSQAJVx .card-box {
    background-color: #141414;
    padding: 2rem;
}

.cid-rrMtSQAJVx h4 {
    font-weight: 500;
    margin-bottom: 0;
    text-align: left;
}

.cid-rrMtSQAJVx p {
    color: white;
    text-align: left;
}

.cid-rrMtSQAJVx .card-wrapper {
    position: relative;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s;
}

.cid-rrMtSQAJVx .card-wrapper:hover {
    /*box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s;*/
}

.cid-rrMtSQAJVx .card-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.cid-rrMtSQAJVx P {
    text-align: left;
}

.cid-rrLxDiHwOn {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #000000;
}

.cid-rrLxDiHwOn .media-container-row .mbr-text {
    color: #707070;
}

/* ADICIONADO */

.videohome {
    position: relative;
    left: 0;
    width: 85%;
    min-width: 100%;
    min-height: 100%;
    z-index: 1;
    margin-bottom: -5px;
}

#content-desktop {
    display: block;
}

#content-mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    #content-desktop {
        display: none;
    }
    #content-mobile {
        display: block;
    }
}

@media (max-width: 768px) {
    .cid-rrLmmgZfdP .nav-item a {
        padding: 0 !important;
        margin: 0 !important;
        display: block;
    }
}

.btn-primary-gold,
.btn-primary-gold:active {
    background-color: darkgoldenrod !important;
    border-color: darkgoldenrod !important;
    color: #000000 !important;
}

.btn-primary-gold:hover,
.btn-primary-gold:focus,
.btn-primary-gold.focus,
.btn-primary-gold.active {
    color: #000000 !important;
    background-color: #a86b00 !important;
    border-color: #a86b00 !important;
}

.iframe-container {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
}

.iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

#facebook {
    background-image: url(../img/footer_facebook.png);
    padding-bottom: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
}

#facebook:hover {
    background-image: url(../img/footer_facebook-hover.png);
    padding-bottom: 20px;
}

#instagram {
    background-image: url(../img/footer_instagram.png);
    padding-bottom: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
}

#instagram:hover {
    background-image: url(../img/footer_instagram-hover.png);
    padding-bottom: 20px;
}

#twitter {
    background-image: url(../img/footer_twitter.png);
    padding-bottom: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
}

#twitter:hover {
    background-image: url(../img/footer_twitter-hover.png);
    padding-bottom: 20px;
}

@media (max-width: 1450px) {
    .cid-rrLmmgZfdP .nav-item a {
        padding: 5px !important;
        margin: 0 !important;
        display: block;
        font-size: 0.7rem;
    }
    .cid-rrLmmgZfdP2 .navbar {
        transition: none;
        z-index: 3;
        padding-top: 10px;
        padding-right: 15px;
        word-break: normal;
        font-size: 0.5rem !important;
    }
}

@media (max-width: 1230px) {
    .cid-rrLmmgZfdP .nav-item a {
        padding: 3px !important;
        margin: 0 !important;
        display: block;
        font-size: 0.7rem;
        letter-spacing: 0;
    }
    .cid-rrLmmgZfdP .btn {
        font-size: 0.8rem;
        white-space: nowrap;
        padding: 0.5rem;
    }
}
