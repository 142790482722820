.checkout-thanks > p{
    text-align: center;
    color: #92ed3e;
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.checkout-thanks > span{
    width: 60px;
    border-bottom: #92ed3e;
    border-width: 0 0 1px 0;
    border-style: solid;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
}

.transaction_feedback{
    position: relative;
    width: 487px;
    height: 150px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    background: #1c1c1c;
}

.transaction_aprovado {
    border: #92ed3e;
    border-width: 1px;
    border-style: solid;
}

.transaction_aguardando {
    border: #fec501;
    border-width: 1px;
    border-style: solid;
}

.transaction_feedback img{
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 12px;
}

.transaction_feedback p{
    position: relative;
    text-align: center;
    top: 82px;
    font-size: 14px;
}

#miniContainers{
    position: relative;
    margin: auto;
    width: fit-content;
    top: 28px;
}

.miniContainers{
    position: relative;
    width: 236px;
    height: 215px;
    margin: 3px;
    text-align: center;
    display: inline-grid;
    border: #444444;
    border-width: 1px;
    border-style: solid;
    background: #1c1c1c;
    text-transform: uppercase;
}

.miniContainersWhite {
    border: #9e9c9cf2;
    background: #F2F2F2;
    border-style: solid;
    border-width: 1px;
}

.miniContainers div {
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
}

.miniContainers div:nth-of-type(1){
    background: url("../img/cart/thanks/bg0.png");
}
.miniContainers div:nth-of-type(2){
    background: url("../img/cart/thanks/bg1.png");
}
.miniContainers div:nth-of-type(3){
    background: url("../img/cart/thanks/bg2.png");
}
.miniContainers div:nth-of-type(4){
    background: url("../img/cart/thanks/bg3.png");
}

.miniContainers p{
    text-align: center;
    color: #92ed3e;
    font-size: 15px;
    font-weight: 500;
    margin-top: 14px;
}

.miniContainersWhite p {
    color: #44bc50
}

.miniContainers img{
    margin: auto;
    margin-top: -5px;
    height: 60px;
}

.miniContainers a{
    width: 156px;
    height: 40px;
    line-height: 40px;
    margin: auto;
    border: #92ed3e;
    border-width: 1px;
    border-style: solid;
    color: #92ed3e;
    background: #151515;
    font-size: 14px;
    margin-top: 0px;
    cursor: pointer;
}

.miniContainersWhite a{
    background: #44bc50;
    border: #437748;
    color: #000;
    border-style: solid;
    border-width: 1px;
}

.titfinal {
    font-size: 22px;
    font-weight: normal;
    text-transform: uppercase;
    color: #91EC3D;
    line-height: 1.273em;
    margin: 0 0 1.455em 0;
    margin-bottom: 1.455em;
    margin-bottom: 1.455em;
    position: relative;
    display: block;
    text-align: center;
}
