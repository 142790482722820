
/* SHOW MENU ON HOVER */

li.nav-item.dropdown:hover>.dropdown-menu {
    display: block;
}

/* FIX ON FOOTER */

#footer {
    position: relative !important;
}

/* WHITE THEME */

.wt-text-green {
    color: #44BC50 !important;
}

.wt-text-black {
    color: black !important;
}

.btn-secondary-white,
.btn-secondary-white:active {
    background-color: #44BC50 !important;
    border-color: #44BC50 !important;
    color: #000000 !important;
}

.btn-secondary-white:hover,
a.btn-secondary-white:not(.btn-form):hover,
a.btn-secondary-white:not(.btn-form):focus {
    background-color: #44BC50 !important;
    border-color: #44BC50 !important;
    color: #000000 !important;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2) !important;
    -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2) !important;
}

.form-control-white,
.cart-page .coin .atual.form-control-white,
.cart-page .coin .coin-opt.form-control-white,
.cart-page .form-checks.form-control-white {
    background: #f9f9f9;
    border: 1px solid #c3c3c3;
    color: #495057;
    box-shadow: none;
    text-shadow: none;
}

.wt-bg-white {
    background-color: #f2f2f2 !important;
}

.wt-bg-gray {
    background-color: #141414 !important;
}

.bl-bg-footer {
    background-image: url(../img/BG_footer.jpg);
    background-color: black;
}

.wt-bg-footer {
    background-image: url(../img/BG_footer-white.jpg);
    background-color: #dadbdd;
}

.wt-table {
    border: 1px solid #44BC50 !important;
}

.wt-table td {
    border-top: 1px solid #44BC50 !important;
}

.bl-bg {
    background: black;
}

.btn {
    margin: 0;
}

#main.account,
#main.create-account {
    background: url(../img/new-panel/register_back.jpg) center top no-repeat fixed #0c0c0c;
    padding-top: 80px;
}
.dataTables_wrapper .row {
    width: 100%;
}

@media screen and (min-width: 1500px) {
    #wrapper {
        min-height: 600px !important;
    }
}

@media screen and (min-width: 1900px) {
    #wrapper {
        min-height: 750px !important;
    }
}

@media screen and (min-width: 2500px) {
    #wrapper {
        min-height: 1050px !important;
    }
}

@media screen and (min-width: 3000px) {
    #wrapper {
        min-height: 1550px !important;
    }
}

.mobile-only {
    display: none;
}

@media screen and (max-width: 767px) {
    .game-cover {
        height: 400px !important;
    }
    .mobile-only {
        display: block;
    }
    .desktop-visible-only {
        visibility: hidden;
    }
}

.lang a img {
    max-width: 24px;
}

td.inherit a,
a.inherit {
    color: inherit !important;
}

.btn-cupom {
    word-break: inherit !important;
    padding: 9px 6px !important;
}