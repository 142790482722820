.form-control-white, .cart-page .coin .atual.form-control-white, .cart-page .coin .coin-opt.form-control-white, .cart-page .form-checks.form-control-white {
	background: #f9f9f9 !important;
	border: 1px solid #c3c3c3 !important;
	color: #495057 !important;
	box-shadow: none !important;
	text-shadow: none !important;
}

.wt-bg-white {
	background-color: #f2f2f2!important;
}

.bl-bg-footer {
	background-image: url(../img/BG_footer.jpg);
	background-color: black;
}

.wt-bg-footer {
	background-image: url(../img/BG_footer-white.jpg);
	background-color: #dadbdd;
}

.wt-table {
	border: 1px solid #408bca!important;
}

.wt-table td {
	border-top: 1px solid #408bca!important;
}

.bl-bg {
	background: black;
}

.btn {
	margin:0;
}

#main.account, #main.create-account {
	background: url(../img/bg.jpg) center top no-repeat fixed #0c0c0c;
	padding-top: 80px;
}

.dataTables_wrapper .row {
    width: 100%;
}

@media screen and (min-width: 1500px) {
    #wrapper {
        min-height:600px!important;
    }
}

@media screen and (min-width: 1900px) {
    #wrapper {
        min-height:750px!important;
    }
}

@media screen and (min-width: 2500px) {
    #wrapper {
        min-height:1050px!important;
    }
}

@media screen and (min-width: 3000px) {
    #wrapper {
        min-height:1550px!important;
    }
}

.mobile-only {
	display: none;
}

@media screen and (max-width: 767px) {
	.game-cover {
		height: 400px!important;
	}

	.mobile-only {
		display: block;
	}

	.desktop-visible-only {
		visibility: hidden;
	}
}

.lang a img {
	max-width: 24px;
}

td.inherit a, a.inherit {
	color: inherit!important;
}